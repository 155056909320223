import { FC, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ClickAwayListener, Divider, SvgIcon, Tooltip, Typography } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { Clipboard } from '@capacitor/clipboard'
import { ReactComponent as ArrowBackIcon } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as ChevronLeftIcon } from 'assets/icon/designsystem/chevron_left.svg'
import { ReactComponent as ChevronRightIcon } from 'assets/icon/designsystem/chevron_right.svg'
import { ReactComponent as ContentCopyIcon } from 'assets/icon/designsystem/content_copy.svg'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import { ReactComponent as FilterAltIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
import { ReactComponent as KeyboardArrowDownIcon } from 'assets/icon/designsystem/keyboard_arrow_down.svg'
import { ReactComponent as KeyboardArrowUpIcon } from 'assets/icon/designsystem/keyboard_arrow_up.svg'
import { ReactComponent as MoreIcon } from 'assets/icon/designsystem/more_vertical.svg'
import { ReactComponent as PrintIcon } from '@obeta/assets//icon/designsystem/print.svg'
import { ReactComponent as TrashIcon } from 'assets/icon/designsystem/delete_outline.svg'

import {
  OfferDetailHeaderClerkDataItem,
  OfferDetailHeaderClerkDataType,
} from './OfferDetailHeaderClerkDataItem'
import { OfferDetailHeaderInfoItem } from './OfferDetailHeaderInfoItem'
import { OfferDetailHeaderDatesContent } from './OfferDetailHeaderDatesContent'
import { OfferFlagItem } from './OfferFlagItem'
import { ScannerList } from '../scannerlist/ScannerList'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useDispatch } from 'react-redux'
import { DropdownOption, FilterSection } from '@obeta/models/lib/models/UI'
import {
  INITIAL_OFFER_DETAILS_FILTERS,
  MultiSelectionNumber,
  OfferDetailsFilter,
  OfferDetailsURLSearchParams,
} from '@obeta/models/lib/models'
import { OfferOrderDir, OfferV3 } from '@obeta/schema'
import { ScannerListType } from '@obeta/models/lib/models/PrintService/PrintInput'
import { useOfferDetailsContext } from '@obeta/data/lib/stores/useOfferDetailsContext'
import styles from './OfferDetailHeader.module.scss'
import { Checkbox } from '../checkbox/Checkbox'
import { Dropdown2 } from '../dropdown2/Dropdown2'
import {
  SecondaryIconButton,
  TertiaryButton,
  TertiaryIconButton,
} from '../custom-button/CustomButton'
import { LoadingIndicator } from '../../lib/split-button/LoadingIndicator'
import { MobileFilters } from '../filters/Filters'
import { Modal } from '../modal/Modal'
import { OfferItemsFilterBar } from '../offers/OfferItemsFilterBar'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'
import { RadioGroup } from '../radio-group/RadioGroup'
import { SearchField } from '../search-field/SearchField'
import { SimpleInfoBlock } from '../notifications/SimpleInfoBlock'
import { createDownloadableOfferAction } from '@obeta/data/lib/actions'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { normalizePrice } from '@obeta/utils/lib/data-formatter/normalizePrice'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import {
  getURLSearchParamsByLocationSearch,
  getURLSearchParamsValue,
  updateDetailsFilters,
  updateURLSearchParams,
} from '@obeta/utils/lib/virtualized-list'

export type OfferDetailHeaderProps = {
  offer: OfferV3 // replace with OfferForDetailsPage type when available
  selectedOfferItems: MultiSelectionNumber
  showDetails?: boolean
  onSortByPositionNumber: (filter: OfferOrderDir) => void
  onSelectAll: () => void
  onSetShowDetails: (showDetails: boolean) => void
  setShowOfferCancellationDialog: (show: boolean) => void
}

export const OfferDetailHeader: FC<OfferDetailHeaderProps> = (props) => {
  const {
    offer,
    selectedOfferItems,
    showDetails,
    onSelectAll,
    onSetShowDetails,
    setShowOfferCancellationDialog,
  } = props

  const { mobile, tablet, tabletWide, desktop } = useBreakpoints()
  const dispatch = useDispatch()
  const showModifiedSumsForOffer = useFeatureToggle('UseShowModifiedSumsForOffer')
  const history = useHistory()
  const { t } = useTranslation()

  // Offer details store
  const { facets, totalItemCount } = useOfferDetailsContext()

  // Component state
  const [clerkTooltip, setClerkTooltip] = useState<boolean>(false)
  const [datesTooltip, setDatesTooltip] = useState<boolean>(false)
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const [initialFilters, setInitialFilters] = useState<{ orderDir: OfferOrderDir } | undefined>(
    undefined
  )
  const [isDownloadButtonActive, setIsDownloadButtonActive] = useState<boolean>(true)
  const [isOpenScannerlist, setIsOpenScannerlist] = useState<boolean>(false)
  const [moreMenuOpen, setMoreMenuOpen] = useState<boolean>(false)
  const itemCountAsString = offer.itemCount > 0 ? ` (${offer.itemCount})` : ''
  const offerDownloadable = offer.itemCount && offer.itemCount <= 500

  const orderDirOptions: DropdownOption[] = [
    { value: 'ASC', label: t('OFFERS.DETAIL.ASCENDING') },
    { value: 'DESC', label: t('OFFERS.DETAIL.DESCENDING') },
  ]

  const supplierOptions: DropdownOption[] = [{ value: 'all', label: t('MAIN.SHOW_ALL') }]

  // Add supplier options by facets and sort them alphabetically
  if (facets.supplier) {
    const sortedSuppliers = facets.supplier.sort((a, b) => a.name.localeCompare(b.name))
    for (const supplierItem of sortedSuppliers) {
      supplierOptions.push({
        label: `${supplierItem.name} (${supplierItem.count})`,
        value: supplierItem.id,
      })
    }
  }

  // Get difference between enddate and today, then add 1(!) to be able to display the duration the offer is still valid for
  const daysOfferStillValidFor = dayjs(offer.endDate).diff(dayjs(), 'day') + 1

  const onSearch = useCallback(
    (searchTerm: string) => {
      let urlParams = getURLSearchParamsByLocationSearch<OfferDetailsURLSearchParams>(
        history.location.search
      )

      urlParams = {
        ...urlParams,
        searchTerm,
      }

      updateURLSearchParams(urlParams, history, `/offer-details/${offer.offerId}`, 'id')
    },
    [history, offer.offerId]
  )

  // Reused ui components
  const buttonFilters = (
    <div className={styles.buttonFilters}>
      <SecondaryIconButton
        icon={<FilterAltIcon />}
        onClick={() => setFiltersOpen(true)}
        size="small"
      />
    </div>
  )

  const checkboxSelectAll = (
    <Checkbox
      checked={selectedOfferItems.selectAll}
      indeterminate={
        (selectedOfferItems.selectAll && selectedOfferItems.exclude.length > 0) ||
        selectedOfferItems.include.length > 0
      }
      label={t('COMMON.SELECT_ALL')}
      onChange={onSelectAll}
    />
  )

  const filterSections: FilterSection[] = [
    {
      id: 'orderDir',
      component: (
        <RadioGroup
          items={orderDirOptions}
          value={
            getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
              'orderDir',
              history?.location?.search ?? '',
              INITIAL_OFFER_DETAILS_FILTERS
            ) ?? ''
          }
          onChange={(value) => onOrderDir(value as OfferOrderDir)}
        />
      ),
      title: t('OFFERS.DETAIL.SORT_BY_POSITION_NUMBER'),
    },
    {
      id: 'supplier',
      component: (
        <RadioGroup
          items={supplierOptions}
          value={
            getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
              'supplierId',
              history?.location?.search ?? '',
              INITIAL_OFFER_DETAILS_FILTERS
            ) ?? 'all'
          }
          onChange={(value) => onFilterBySupplier(value as string)}
        />
      ),
      title: t('FILTER.SUPPLIER'),
    },
  ]

  const searchField = (
    <SearchField
      placeholder={t('TEMPLATES.DETAILS_SEARCH_PLACEHOLDER')}
      value={
        getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
          'searchTerm',
          history?.location?.search ?? '',
          INITIAL_OFFER_DETAILS_FILTERS
        ) ?? ''
      }
      onChange={onSearch}
      onReset={() => onSearch('')}
    />
  )

  // Subscribe to remove LoadingIndicator
  useEffect(() => {
    const sub = getEventSubscription().subscribe((event) => {
      if (
        event.notificationType === NotificationType.SapOfferPdfCreated ||
        event.notificationType === NotificationType.SapOfferPdfFailed
      ) {
        setIsDownloadButtonActive(true)
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [history])

  const onDownloadClick = (isMobile: boolean) => {
    if (isMobile) {
      setMoreMenuOpen(false)
    }
    dispatch(createDownloadableOfferAction(offer.offerId))
    setIsDownloadButtonActive(false)
  }

  /**
   * Handler to close mobile filters. Reset filters state.
   */
  const onMobileFiltersClose = useCallback(() => {
    if (initialFilters) {
      onOrderDir(initialFilters.orderDir)
    }
    setInitialFilters(undefined)
    setFiltersOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFilters])

  /**
   * Handler to close more menu (mobile).
   */
  const onMoreMenuClose = () => {
    setMoreMenuOpen(false)
  }

  const onFilterBySupplier = useCallback(
    (supplierId: string) => {
      const sId = supplierId === 'all' ? '' : supplierId
      updateDetailsFilters(offer.offerId, 'offer-details', history, 'supplierId', sId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, offer.offerId]
  )

  const onOrderDir = useCallback(
    (orderDir: OfferOrderDir) => {
      updateDetailsFilters(offer.offerId, 'offer-details', history, 'orderDir', orderDir)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, offer.offerId]
  )

  return (
    <div className={styles.wrapper}>
      <Modal isFullScreen open={mobile && !!showDetails} onClose={() => onSetShowDetails(false)}>
        <div className={styles.details}>
          <div className={clsx(styles.toolbar, styles.toolbarDetails)}>
            <TertiaryButton
              fullWidth={false}
              leftIcon={<SvgIcon component={ChevronLeftIcon} />}
              size="large"
              onClick={() => onSetShowDetails(false)}
            >
              {t('OFFERS.DETAIL.SHOW_DETAILS')}
            </TertiaryButton>
          </div>
          <div className={styles.detailsInfo}>
            {offer.customerReference.length > 0 && (
              <OfferDetailHeaderInfoItem
                flexDirectionColumn
                title={t('OFFERS.META_DATA.CUSTOMER_REFERENCE')}
                value={offer.customerReference}
              />
            )}
            {offer.estate.length > 0 && (
              <OfferDetailHeaderInfoItem
                flexDirectionColumn
                title={t('OFFERS.META_DATA.ESTATE')}
                value={offer.estate}
              />
            )}
            <OfferDetailHeaderInfoItem
              flexDirectionColumn
              title={t('OFFERS.META_DATA.CLERK')}
              value={offer.clerk.name}
            >
              <div className={styles.clerkData}>
                {offer.clerk?.telephone && (
                  <OfferDetailHeaderClerkDataItem
                    clerkDataType={OfferDetailHeaderClerkDataType.Telephone}
                    value={offer.clerk.telephone}
                  />
                )}
                {offer.clerk?.email && (
                  <OfferDetailHeaderClerkDataItem
                    clerkDataType={OfferDetailHeaderClerkDataType.EMail}
                    value={offer.clerk.email}
                  />
                )}
              </div>
            </OfferDetailHeaderInfoItem>
          </div>
          <OfferDetailHeaderDatesContent
            id={offer.offerId}
            endDate={offer.endDate}
            lastUpdated={offer.lastUpdated}
            startDate={offer.startDate}
          />
        </div>
      </Modal>
      <div className={clsx(styles.toolbar, styles.toolbarTop)}>
        <TertiaryButton
          fullWidth={false}
          leftIcon={<SvgIcon component={ArrowBackIcon} />}
          size={mobile ? 'large' : 'small'}
          onClick={() => history.push(isUiTarget('app') ? '/offers' : '/offer-list')}
        >
          {t('OFFERS.BACK_TO_OVERVIEW')}
        </TertiaryButton>
        {mobile && (
          <>
            <TertiaryIconButton
              id="context-menu-button"
              aria-controls={moreMenuOpen ? 'context-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={moreMenuOpen ? 'true' : undefined}
              icon={<MoreIcon />}
              size="large"
              onClick={() => setMoreMenuOpen(true)}
            />
            <PopoverFromBottom
              isOpen={moreMenuOpen}
              paperStyles={styles.moreMenu}
              onClose={onMoreMenuClose}
            >
              {offerDownloadable && (
                <div className={styles.moreMenuItem}>
                  <TertiaryButton
                    classes={styles.moreMenuItem}
                    leftIcon={<DownloadIcon />}
                    size="large"
                    onClick={() => onDownloadClick(true)}
                  >
                    {t('OFFERS.ACTIONS.DOWNLOAD')}
                  </TertiaryButton>
                </div>
              )}
              <TertiaryButton
                disabled={!selectedOfferItems.selectAll && !selectedOfferItems.include.length}
                leftIcon={<PrintIcon />}
                onClick={() => setIsOpenScannerlist(true)}
              >
                {t('OFFERS.ACTIONS.PRINT_SCANNER_LIST')}
              </TertiaryButton>
            </PopoverFromBottom>
          </>
        )}
      </div>
      {mobile && <Divider className={styles.divider} />}
      <div className={clsx(styles.toolbar, styles.toolbarBottom)}>
        <div className={styles.flags}>
          {offer.offerFlags.map((flag) => (
            <OfferFlagItem key={flag} flag={flag} />
          ))}
        </div>
        {!mobile && (
          <div className={styles.actions}>
            {offerDownloadable && (
              <TertiaryButton leftIcon={<DownloadIcon />} onClick={() => onDownloadClick(false)}>
                {t('OFFERS.ACTIONS.DOWNLOAD')}
                {!isDownloadButtonActive && <LoadingIndicator />}
              </TertiaryButton>
            )}
            <TertiaryButton
              disabled={!selectedOfferItems.selectAll && !selectedOfferItems.include.length}
              leftIcon={<PrintIcon />}
              onClick={() => setIsOpenScannerlist(true)}
            >
              {t('OFFERS.ACTIONS.PRINT_SCANNER_LIST')}
            </TertiaryButton>
            <TertiaryButton
              leftIcon={<TrashIcon />}
              onClick={() => setShowOfferCancellationDialog(true)}
            >
              {t('OFFERS.CANCELLATION.CANCEL_OFFER')}
            </TertiaryButton>
          </div>
        )}
        {isOpenScannerlist && (
          <ScannerList
            childIdsExcluded={selectedOfferItems?.exclude}
            parentId={offer.offerId}
            childIds={selectedOfferItems?.include}
            type={ScannerListType.OfferDetails}
            isOpen={isOpenScannerlist}
            onClose={() => setIsOpenScannerlist(false)}
          />
        )}
      </div>
      <div className={clsx((tabletWide || desktop) && styles.titleAndPriceWrapper)}>
        <Typography className={styles.title} variant="h3">
          {`${offer.offerName}${itemCountAsString}`}
        </Typography>
        <div
          className={clsx(
            styles.titleAndPriceWrapperText,
            (mobile || tablet) && styles.halfMarginTop
          )}
        >
          <Typography variant={'subTitle'}>
            {t('OFFERS.META_DATA.TOTAL_NET_PRICE_EXCLUDING_METAL')}
          </Typography>
          <Typography variant={'headline4Bold'}>
            {showModifiedSumsForOffer
              ? normalizePrice(offer.withoutAdditionsPrice)
              : normalizePrice(offer.netPrice)}
          </Typography>
        </div>
      </div>
      <div className={clsx(styles.infoAndAlternativePriceWrapper, mobile && styles.halfMarginTop)}>
        {(desktop || tabletWide) && (
          <div className={styles.infoTop}>
            {offer.customerReference.length > 0 && (
              <OfferDetailHeaderInfoItem
                title={`${t('OFFERS.META_DATA.CUSTOMER_REFERENCE')}:`}
                value={offer.customerReference}
              />
            )}
            {offer.estate.length > 0 && (
              <OfferDetailHeaderInfoItem
                title={`${t('OFFERS.META_DATA.ESTATE')}:`}
                value={offer.estate}
              />
            )}
          </div>
        )}
        {showModifiedSumsForOffer && offer.requirementPrice > 0 && (
          <div
            className={clsx(
              styles.titleAndPriceWrapperText,
              mobile && styles.flexDirectionColumn,
              tablet && styles.halfMarginTop
            )}
          >
            <Typography variant={'subTitle'}>
              {t('OFFERS.META_DATA.ALTERNATIVE_TOTAL_SUM')}
            </Typography>
            <Typography variant={'headline4Bold'}>
              {normalizePrice(offer.requirementPrice)}
            </Typography>
          </div>
        )}
        {tablet && (
          <Divider
            className={
              showModifiedSumsForOffer && offer.requirementPrice > 0
                ? styles.dividerInclQuarterTopSpacing
                : styles.dividerInclTopSpacing
            }
          />
        )}
        {tablet && (
          <div className={styles.infoTop}>
            {offer.customerReference.length > 0 && (
              <OfferDetailHeaderInfoItem
                title={`${t('OFFERS.META_DATA.CUSTOMER_REFERENCE')}:`}
                value={offer.customerReference}
              />
            )}
            {offer.estate.length > 0 && (
              <OfferDetailHeaderInfoItem
                title={`${t('OFFERS.META_DATA.ESTATE')}:`}
                value={offer.estate}
              />
            )}
          </div>
        )}
      </div>
      {showModifiedSumsForOffer && offer.requirementPrice > 0 && !mobile && (
        <SimpleInfoBlock
          variant="dark"
          Icon={InfoIcon}
          body={t('OFFERS.META_DATA.INFO_TOTAL_NET_PRICE_EXCLUDES_REQUIREMENT_ITEMS')}
        />
      )}
      <Divider
        className={
          showModifiedSumsForOffer && offer.requirementPrice > 0
            ? styles.dividerInclTopSpacing
            : styles.divider
        }
      />
      {mobile ? (
        <div className={styles.showDetails}>
          <TertiaryButton
            fullWidth={false}
            rightIcon={<ChevronRightIcon />}
            onClick={() => onSetShowDetails(true)}
          >
            {t('OFFERS.DETAIL.SHOW_DETAILS')}
          </TertiaryButton>
        </div>
      ) : (
        <div className={styles.infoBottom}>
          <OfferDetailHeaderInfoItem
            flexDirectionColumn
            title={t('OFFERS.META_DATA.ID')}
            value={mobile ? offer.offerId : ''}
          >
            <TertiaryButton
              rightIcon={<ContentCopyIcon />}
              size={desktop ? 'small' : 'large'}
              onClick={async () => {
                await Clipboard.write({ string: offer.offerId })

                getEventSubscription().next({
                  type: EventType.Toast,
                  notificationType: NotificationType.Success,
                  id: 'copy-property',
                  options: {
                    heading: offer.offerId,
                    body: t<string>('MAIN.WAS_COPIED'),
                  },
                })
              }}
            >
              {offer.offerId}
            </TertiaryButton>
          </OfferDetailHeaderInfoItem>
          <OfferDetailHeaderInfoItem flexDirectionColumn title={t('OFFERS.META_DATA.CLERK')}>
            {offer.clerk?.email || offer.clerk?.telephone ? (
              <ClickAwayListener onClickAway={() => setClerkTooltip(false)}>
                <div>
                  <Tooltip
                    arrow
                    open={clerkTooltip}
                    title={
                      <div className={styles.clerkData}>
                        {offer.clerk.telephone && (
                          <OfferDetailHeaderClerkDataItem
                            clerkDataType={OfferDetailHeaderClerkDataType.Telephone}
                            value={offer.clerk.telephone}
                          />
                        )}
                        {offer.clerk.email && (
                          <OfferDetailHeaderClerkDataItem
                            clerkDataType={OfferDetailHeaderClerkDataType.EMail}
                            value={offer.clerk.email}
                          />
                        )}
                      </div>
                    }
                  >
                    <div>
                      <TertiaryButton
                        rightIcon={<InfoIcon />}
                        size={desktop ? 'small' : 'large'}
                        onClick={() => setClerkTooltip(!clerkTooltip)}
                      >
                        {offer.clerk.name}
                      </TertiaryButton>
                    </div>
                  </Tooltip>
                </div>
              </ClickAwayListener>
            ) : (
              <Typography variant="boldText">{offer.clerk.name}</Typography>
            )}
          </OfferDetailHeaderInfoItem>
          <OfferDetailHeaderInfoItem
            chromaticIgnore
            flexDirectionColumn
            title={
              // Get difference between enddate and today, then add 1(!) to be able to display the duration the offer is still valid for
              daysOfferStillValidFor > 1
                ? t('OFFERS.META_DATA.END_DATE_LONG_MULTIPLE', { days: daysOfferStillValidFor })
                : t('OFFERS.META_DATA.END_DATE_LONG_SINGLE', { days: daysOfferStillValidFor })
            }
          >
            <ClickAwayListener onClickAway={() => setDatesTooltip(false)}>
              <Tooltip
                arrow
                open={datesTooltip}
                title={
                  <OfferDetailHeaderDatesContent
                    id={offer.offerId}
                    endDate={offer.endDate}
                    lastUpdated={offer.lastUpdated}
                    startDate={offer.startDate}
                  />
                }
              >
                <div className={styles.dates}>
                  <TertiaryButton
                    rightIcon={datesTooltip ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    size={desktop ? 'small' : 'large'}
                    onClick={() => setDatesTooltip(!datesTooltip)}
                  >
                    {dateFormatter(offer.endDate)}
                  </TertiaryButton>
                </div>
              </Tooltip>
            </ClickAwayListener>
          </OfferDetailHeaderInfoItem>
        </div>
      )}
      {mobile && (
        <div className={styles.searchAndFilters}>
          <div className={styles.searchAndFiltersMobileTop}>
            {searchField}
            {buttonFilters}
          </div>
          {checkboxSelectAll}
        </div>
      )}
      {!mobile && (
        <div className={styles.searchAndFilters}>
          {checkboxSelectAll}
          {searchField}
          {tablet && buttonFilters}
        </div>
      )}
      <div className={styles.filterAndSortWrapper}>
        <OfferItemsFilterBar id={offer.offerId} />
        {!mobile && !tablet && (
          <div className={styles.filterAndSortWrapperDropdowns}>
            <Dropdown2
              label={t('OFFERS.DETAIL.SORT_BY_POSITION_NUMBER')}
              options={orderDirOptions}
              value={
                getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
                  'orderDir',
                  history?.location?.search ?? '',
                  INITIAL_OFFER_DETAILS_FILTERS
                ) ?? ''
              }
              onChange={(value) => onOrderDir(value as OfferOrderDir)}
            />
            <Dropdown2
              label={t('FILTER.SUPPLIER')}
              options={supplierOptions}
              value={
                getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
                  'supplierId',
                  history?.location?.search ?? '',
                  INITIAL_OFFER_DETAILS_FILTERS
                ) ?? 'all'
              }
              onChange={(value) => onFilterBySupplier(value as string)}
            />
          </div>
        )}
      </div>
      <MobileFilters
        amount={totalItemCount}
        open={filtersOpen}
        sections={filterSections}
        onClose={onMobileFiltersClose}
        onOpen={() =>
          setInitialFilters({
            orderDir:
              (getURLSearchParamsValue<OfferDetailsFilter, OfferDetailsURLSearchParams>(
                'orderDir',
                history?.location?.search ?? '',
                INITIAL_OFFER_DETAILS_FILTERS
              ) as OfferOrderDir) ?? INITIAL_OFFER_DETAILS_FILTERS.orderDir,
          })
        }
        onSubmit={() => setFiltersOpen(false)}
      />
    </div>
  )
}
