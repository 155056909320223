import React, { useState } from 'react'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { Header } from './Header'
import { TSettingsView, ESettingsSection } from './types'
import { FirmDataSettingsForm } from './FirmDataSettings'
import { PriceSettings } from './PriceSettings'
import { Iframe } from '@obeta/components/lib/legacy-shop/Iframe'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const SettingsPage: React.FC = () => {
  useAuthenticatedRoute()

  const isNewCompanySettings = useFeatureToggle('UseNewCompanySettings')
  const legacyRoute = 'benutzer/einstellungen'

  return !isNewCompanySettings ? <Iframe legacyShopPageUrl={legacyRoute} /> : <NewSettingsPage />
}

export const NewSettingsPage = () => {
  const [activeView, setActiveView] = useState<TSettingsView>(ESettingsSection.FIRM_DATA)
  const getPageContent = () => {
    switch (activeView) {
      case ESettingsSection.FIRM_DATA:
        return <FirmDataSettingsForm />
      case ESettingsSection.PRICE_DATA:
        return <PriceSettings />
      default:
        return null
    }
  }
  return (
    <>
      <Header activeView={activeView} setActiveView={setActiveView} />
      {getPageContent()}
    </>
  )
}
