import { ESettingsSection, IMenuItem } from './types'

export const menuItems: IMenuItem[] = [
  {
    title: ESettingsSection.DATA_FOR_PRINT,
    options: [{ title: ESettingsSection.FIRM_DATA }, { title: ESettingsSection.PRICE_DATA }],
  },
  // { title: ESettingsSection.DELIVERY_ADDRESSESS },
  // { title: ESettingsSection.COMMISSION },
]
