import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { PossibleProject } from '@obeta/models/lib/schema-models/possible-projects'
import { gqlPossibleProjects } from '../../queries/possibleProjects'
import { GetPossibleProjectsQuery, GetPossibleProjectsQueryVariables } from '@obeta/schema'

export const usePossibleProjects = () => {
  const [possibleProjects, setPossibleProjects] = useState<PossibleProject[]>()
  useQuery<GetPossibleProjectsQuery, GetPossibleProjectsQueryVariables>(gqlPossibleProjects, {
    skip: possibleProjects !== undefined,
    onCompleted: (data) => {
      setPossibleProjects(data.getPossibleProjects)
    },
  })
  return possibleProjects
}
