import React, { FC, MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import clsx from 'clsx'
import { ReactComponent as ArrowForwardIcon } from '@obeta/assets/icon/designsystem/arrow_forward.svg'
import { ReactComponent as CSVIcon } from 'assets/icon/designsystem/csv-file-format-extension.svg'
import { ReactComponent as FilterIcon } from '@obeta/assets/icon/designsystem/filter_alt.svg'
import { ReactComponent as ListIcon } from '@obeta/assets/icon/designsystem/list.svg'
import { ReactComponent as MoreIcon } from '@obeta/assets/icon/designsystem/more_vertical.svg'
import { ReactComponent as NewIcon } from 'assets/icon/designsystem/new.svg'
import { ReactComponent as StickyNoteIcon } from '@obeta/assets/icon/designsystem/sticky_note_2.svg'
import { Feedback } from '@obeta/components/lib/feedback/Feedback'
import { ConstructionDocumentationPicker } from '@obeta/components/lib/orders/ConstructionDocumentationPicker'
import { OrderListFiltersItem } from '@obeta/components/lib/orders/OrderListFiltersItem'
import { OrderListFiltersItemState } from '@obeta/components/lib/orders/OrderListFiltersItemState'
import { OrderListFiltersOrderType } from '@obeta/components/lib/orders/OrderListFiltersOrderType'
import { OrderListFiltersShippingType } from '@obeta/components/lib/orders/OrderListFiltersShippingType'
import { OrderListVirtualized } from '@obeta/components/lib/orders/OrderListVirtualized'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useDispatchConstructionDocumentation } from '@obeta/data/lib/hooks/useDispatchConstructionDocumentation'
import { useGetOrdersExport } from '@obeta/data/lib/hooks/useGetOrdersExport'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useOrderListContext } from '@obeta/data/lib/stores/useOrderListContext'
import { useOrderSearch } from '@obeta/data/lib/hooks/useOrderSearch'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { FilterSection } from '@obeta/models/lib/models/UI'
import { OrdersInput } from '@obeta/schema'
import {
  DEFAULT_ORDER_LIST_FILTERS,
  DEFAULT_ORDER_LIST_QUERY_PARAMS,
  OrderListFilters,
  OrderListURLSearchParams,
} from '@obeta/models/lib/models/Orders'
import { QueryParams } from '@obeta/models/lib/models/VirtualizedList'
import styles from './ordersListPage.module.scss'
import BasicDateRangePicker from '@obeta/components/lib/date-range-picker/BasicDateRangePicker'
import { EmptyCard } from '@obeta/components/lib/empty-card/EmptyCard'
import { Filters, MobileFilters } from '@obeta/components/lib/filters/Filters'
import { ListSkeletons } from '@obeta/components/lib/list-skeletons/ListSkeletons'
import { NoResults } from '@obeta/components/lib/no-results/NoResults'
import { SearchField } from '@obeta/components/lib/search-field/SearchField'
import {
  SecondaryIconButton,
  TertiaryButton,
  TertiaryIconButton,
} from '@obeta/components/lib/custom-button/CustomButton'
import {
  createOrdersInputByQueryParamsAndURLSearchParams,
  getOrderListDateStrings,
  getOrderListFilterValue,
  getOrderListSearchTermValue,
  isOrderListDefaultFilterValues,
} from '@obeta/utils/lib/orders-helpers'
import { useScrollRestorationContext } from '@obeta/data/lib/stores/useScrollRestorationContext'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { OrderListLoading } from '@obeta/components/lib/orders/OrderListLoading'
import {
  getURLSearchParamsByLocationSearch,
  getURLSearchParamsValue,
  updateURLSearchParams,
} from '@obeta/utils/lib/virtualized-list'
import { PopoverFromBottom } from '@obeta/components/lib/popover/PopoverFromBottom'

/**
 * Rendered component for the Orders List Page.
 * @remarks
 * This component displays a list of orders based on the user's search input and filters. It also includes the necessary UI elements for searching, filtering, and viewing order details
 * @returns - The rendered Orders List Page content
 */
const OrdersListPage: FC = () => {
  useAuthenticatedRoute()
  const { mobile, tabletWide, desktop } = useBreakpoints()
  const useOldOrders = useFeatureToggle('UseOldOrders')
  const history = useHistory()
  const { t } = useTranslation()
  const { facets, isLoading, isLoadingPage, orders, totalOrderCount, resetOrders, setIsLoading } =
    useOrderListContext()
  const { orderListLastScrollY, resetOrderListCache } = useScrollRestorationContext()
  const { queryHasOrders, searchOrders } = useOrderSearch()
  const { isLoggedIn, permissions } = useUserDataV2()

  const initiateRefresh = useCallback(
    (params) => {
      // Reset order list
      resetOrders()
      resetOrderListCache()
      setIsLoading(true)

      updateURLSearchParams(params, history, '/orders-list')

      return true
    },
    [history, resetOrderListCache, resetOrders, setIsLoading]
  )

  const { dispatchSmallConstructionDocumentationPdf, dispatchLargeConstructionDocumentationZip } =
    useDispatchConstructionDocumentation()
  const { getOrdersExport } = useGetOrdersExport()

  const useOrderItemsInArrears = useFeatureToggle('UseOrderItemsInArrears')
  const useOrdersCSVExport = useFeatureToggle('UseOrderCSVExport')
  const useOrdersFeedback = useFeatureToggle('UseOrdersFeedback')
  const usePrintBauDoc = useFeatureToggle('UsePrintBauDoc')

  // Component state
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false)
  const [hasOrders, setHasOrders] = useState<boolean | undefined>(undefined)
  const didScroll = useRef<boolean>(false)
  const didInitialSkip = useRef<boolean>(false)
  const [search, setSearch] = useState<string>('')
  const [showConstructionDocumentationPicker, setShowConstructionDocumentationPicker] =
    useState(false)
  const [anchorElContextMenu, setAnchorElContextMenu] = useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorElContextMenu)

  const setDates = (dateFrom: string, dateTo: string) => {
    const params: OrderListURLSearchParams = {
      ...getOrderListDateStrings(dateFrom, dateTo),
      // Reset remaining filters
      itemStates: DEFAULT_ORDER_LIST_FILTERS.itemStates.toString(),
      orderType: DEFAULT_ORDER_LIST_FILTERS.orderType.toString(),
      projectName: DEFAULT_ORDER_LIST_FILTERS.projectName.toString(),
      purchaser: DEFAULT_ORDER_LIST_FILTERS.purchaser.toString(),
      searchTerm: DEFAULT_ORDER_LIST_FILTERS.searchTerm,
      shippingType: DEFAULT_ORDER_LIST_FILTERS.shippingType.toString(),
      supplierIds: DEFAULT_ORDER_LIST_FILTERS.supplierIds.toString(),
    }
    initiateRefresh(params)
    setSearch('')
  }

  const filterSections: FilterSection[] = facets
    ? [
        {
          id: 'states',
          component: facets.itemStates && <OrderListFiltersItemState />,
          title: t('ORDERS.STATES.TITLE'),
        },
        {
          id: 'types',
          component: facets.orderType && <OrderListFiltersOrderType />,
          title: t('ORDERS.TYPES.TITLE'),
        },
        {
          id: 'supplierIds',
          component: facets.supplierIds && <OrderListFiltersItem id="supplierIds" />,
          title: t('ORDERS.SUPPLIERS'),
        },
        {
          id: 'purchaser',
          component: facets.purchaser && <OrderListFiltersItem id="purchaser" />,
          title: t('ORDERS.INFO.PURCHASER'),
        },
        {
          id: 'projectName',
          component: facets.projectName && <OrderListFiltersItem id="projectName" />,
          title: t('ORDERS.PROJECT_NAMES.TITLE'),
        },
        {
          id: 'shippingTypes',
          component: facets.shippingType && <OrderListFiltersShippingType />,
          title: t('ORDERS.SHIPPING.TYPE.TITLE'),
        },
      ]
    : []

  useEffect(() => {
    const checkHasOrders = async () => {
      const qHasOrders = await queryHasOrders()
      qHasOrders ? setHasOrders(true) : setHasOrders(false)
    }

    checkHasOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!history.location.search.includes('memo')) {
      didScroll.current = true
      window.scrollTo({ top: 0 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Create search input if history location search changes
  useEffect(() => {
    // Control to abort any pending request
    const controller = new AbortController()
    const { signal } = controller

    if (isLoggedIn) {
      // Create input object for graphql endpoint
      const urlSearchParams = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
        history.location.search
      )
      const input: OrdersInput = createOrdersInputByQueryParamsAndURLSearchParams(
        DEFAULT_ORDER_LIST_QUERY_PARAMS,
        urlSearchParams
      )

      const searchTerm = getURLSearchParamsValue<OrderListFilters, OrderListURLSearchParams>(
        'searchTerm',
        history.location.search,
        DEFAULT_ORDER_LIST_FILTERS
      )

      if (searchTerm && searchTerm.length > 0) {
        setSearch(searchTerm)
      }

      // Search orders by input body
      if (
        !history.location.search.includes('memo') ||
        orders.length === 0 ||
        didInitialSkip.current
      ) {
        // Pass the abort signal
        searchOrders(input, signal)

        if (!history.location.search.includes('memo')) {
          const search = history.location.search
            ? `${history.location.search}&memo=true`
            : '?memo=true'
          history.replace({
            pathname: history.location.pathname,
            search,
          })
        }
      }
      didInitialSkip.current = true
    }
    return () => {
      // If memo is not set in URL first call will be aborted, because of URL change
      if (history.location.search.includes('memo')) {
        controller.abort()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search, isLoggedIn])

  useEffect(() => {
    const handleScroll = () => {
      if (!isLoadingPage && window.scrollY > 0) {
        orderListLastScrollY.current = window.scrollY
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPage])

  // Handle component search term state to initiate URLSearchParams refresh and search
  useDebouncedEffect(
    () => {
      onSetSearchTerm(search)
    },
    [search],
    1000
  )

  const scrollTo = () => {
    if (!didScroll.current) {
      window.scrollTo({ top: orderListLastScrollY.current })
      didScroll.current = true
    }
  }

  // ######### //
  // CALLBACKS //
  // ######### //

  /**
   * Handler to close mobile filters.
   */
  const onCloseMobileFilters = useCallback(() => {
    let params = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
      history.location.search
    )
    params = {
      ...params,
      // Keep date filters
      ...getOrderListDateStrings(params.dateFrom, params.dateTo),
      // Reset remaining filters
      itemStates: DEFAULT_ORDER_LIST_FILTERS.itemStates.toString(),
      orderType: DEFAULT_ORDER_LIST_FILTERS.orderType.toString(),
      projectName: DEFAULT_ORDER_LIST_FILTERS.projectName.toString(),
      purchaser: DEFAULT_ORDER_LIST_FILTERS.purchaser.toString(),
      shippingType: DEFAULT_ORDER_LIST_FILTERS.shippingType.toString(),
      supplierIds: DEFAULT_ORDER_LIST_FILTERS.supplierIds.toString(),
    }
    updateURLSearchParams(params, history, '/orders-list')
    setFiltersOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, initiateRefresh])

  /**
   * Handler to load more orders while endless scrolling.
   * @param queryParams QueryParams
   */
  const onLoadMoreOrders = useCallback(
    (queryParams: QueryParams) => {
      const urlSearchParams = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
        history.location.search
      )
      const input: OrdersInput = createOrdersInputByQueryParamsAndURLSearchParams(
        queryParams,
        urlSearchParams
      )
      // Search orders by input body
      searchOrders(input)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history.location.search, orders]
  )

  /**
   * Handler to set search term by current input value.
   * @param searchTerm Search term
   */
  const onSetSearchTerm = useCallback(
    (searchTerm: string) => {
      const urlParamsSearchTerm = getURLSearchParamsValue<
        OrderListFilters,
        OrderListURLSearchParams
      >('searchTerm', history.location.search, DEFAULT_ORDER_LIST_FILTERS)

      if (urlParamsSearchTerm !== searchTerm) {
        let params = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
          history.location.search
        )
        params = {
          searchTerm,
          // Keep date filters
          ...getOrderListDateStrings(params.dateFrom, params.dateTo),
          // Reset remaining filters
          itemStates: DEFAULT_ORDER_LIST_FILTERS.itemStates.toString(),
          memo: 'true',
          orderType: DEFAULT_ORDER_LIST_FILTERS.orderType.toString(),
          projectName: DEFAULT_ORDER_LIST_FILTERS.projectName.toString(),
          purchaser: DEFAULT_ORDER_LIST_FILTERS.purchaser.toString(),
          shippingType: DEFAULT_ORDER_LIST_FILTERS.shippingType.toString(),
          supplierIds: DEFAULT_ORDER_LIST_FILTERS.supplierIds.toString(),
        }
        initiateRefresh(params)
      }
    },
    [history.location.search, initiateRefresh]
  )

  // COD-17426 Custom tracking of edge case: User has read permissions, ordered with us before, active filters set,
  // yet no search term chosen and still no search results received
  if (
    permissions?.Orders_canRead &&
    !isLoading &&
    hasOrders &&
    !isOrderListDefaultFilterValues(history.location.search) &&
    getOrderListSearchTermValue(history.location.search) === '' &&
    orders.length === 0
  ) {
    trackCustom('orders-list-no-results-due-to-filtering-without-search-term', {
      params: getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(history.location.search),
    })
  }

  const onDownload = (option: string) => {
    const urlSearchParams = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
      history.location.search
    )
    const input: OrdersInput = createOrdersInputByQueryParamsAndURLSearchParams(
      {
        limit: '500',
        offset: '0',
      },
      urlSearchParams
    )
    if (option === 'small') {
      dispatchSmallConstructionDocumentationPdf(input)
    } else if (option === 'large') {
      dispatchLargeConstructionDocumentationZip(input)
    }
  }

  if (!hasOrders && isLoadingPage) return <OrderListLoading />

  const handleClickOnMoreIcon = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    setAnchorElContextMenu(event.currentTarget)
  }

  const handleRequestCSVExport = () => {
    const urlSearchParams = getURLSearchParamsByLocationSearch<OrderListURLSearchParams>(
      history.location.search
    )
    const input: OrdersInput = createOrdersInputByQueryParamsAndURLSearchParams(
      {
        limit: '500',
        offset: '0',
      },
      urlSearchParams
    )

    const modifiedInput = {
      ...input,
      orderItemLimit: '0', // Note: The backend would hard-reset this to 0 anyway
      limit: totalOrderCount.toString(),
    }

    getOrdersExport(modifiedInput)
  }

  const mobilePopover = (
    <PopoverFromBottom
      isOpen={isOpen}
      onClose={() => setAnchorElContextMenu(null)}
      paperStyles={styles.paper}
    >
      {useOrdersCSVExport && (
        <TertiaryButton
          fullWidth={true}
          size={desktop ? 'small' : 'large'}
          leftIcon={<CSVIcon />}
          onClick={() => handleRequestCSVExport()}
        >
          {t('ORDERS.CSV_EXPORT')}
        </TertiaryButton>
      )}
      <TertiaryButton
        size={desktop ? 'small' : 'large'}
        leftIcon={<ListIcon />}
        onClick={() => history.push(ShopRoutes.OrderItemsInArrears)}
      >
        {t('ORDERS.IN_ARREARS.TITLE')}
      </TertiaryButton>
    </PopoverFromBottom>
  )

  return (
    <>
      <div className={styles.background} />
      <div className={clsx(styles.ordersList, styles.ordersListV1_1)}>
        {permissions?.Orders_canRead && (tabletWide || desktop) && hasOrders && (
          <div className={styles.filters}>
            <Typography className={styles.title} variant="headline3Bold">
              {t('ORDERS.ORDERS')}
            </Typography>
            <BasicDateRangePicker
              mobile={mobile}
              dateFrom={getOrderListFilterValue(history.location.search, 'dateFrom')}
              dateTo={getOrderListFilterValue(history.location.search, 'dateTo')}
              setDates={setDates}
            />
            {facets && <Filters sections={filterSections} />}
          </div>
        )}
        {/* Permission to view orders NOT granted */}
        {!permissions?.Orders_canRead && (
          <div className={styles.empty}>
            <EmptyCard
              subtitle={t('COMMON.NO_PERMISSIONS.SUBTITLE')}
              title={t('COMMON.NO_PERMISSIONS.TITLE')}
            />
          </div>
        )}

        {/* Permission to view orders, however NO prior orders made */}
        {permissions?.Orders_canRead &&
          hasOrders === false &&
          isOrderListDefaultFilterValues(history.location.search) &&
          !isLoading &&
          !isLoadingPage && (
            <div className={styles.empty}>
              <EmptyCard title={t('ORDERS.NO_CURRENT_ORDERS')} />
            </div>
          )}

        {/* Permission to view orders, prior orders made */}
        {permissions?.Orders_canRead && hasOrders && isLoggedIn && (
          <Grid
            className={styles.ordersWrapper}
            direction={'column'}
            alignItems={desktop || tabletWide ? 'center' : 'stretch'}
          >
            <Grid className={clsx(styles.appBarWrapper)}>
              <div className={styles.titleWrapper}>
                <Typography className={styles.title} variant={'h3'} color={'text.primary'}>
                  {isOrderListDefaultFilterValues(history.location.search)
                    ? `${t('ORDERS.ORDERS')} (${totalOrderCount})`
                    : `${totalOrderCount} ${t('ORDERS.RESULTS')}`}
                </Typography>
                <div className={styles.buttonDiv}>
                  {useOrderItemsInArrears && mobile && (
                    <div className={styles.newIconAndButtonWrapper}>
                      <NewIcon />
                      <TertiaryIconButton
                        size={'large'}
                        icon={<MoreIcon />}
                        onClick={handleClickOnMoreIcon}
                      ></TertiaryIconButton>
                    </div>
                  )}
                  {mobilePopover}
                  {!mobile && useOrdersCSVExport && (
                    <TertiaryButton
                      disabled={totalOrderCount === 0}
                      size={desktop ? 'small' : 'large'}
                      leftIcon={<CSVIcon />}
                      onClick={handleRequestCSVExport}
                    >
                      {t('ORDERS.EXPORT')}
                    </TertiaryButton>
                  )}
                  {useOrderItemsInArrears && !mobile && (
                    <div className={styles.newIconAndButtonWrapper}>
                      <NewIcon />
                      <TertiaryButton
                        size={desktop ? 'small' : 'large'}
                        leftIcon={<ListIcon />}
                        onClick={() => history.push(ShopRoutes.OrderItemsInArrears)}
                      >
                        {t('ORDERS.IN_ARREARS.TITLE')}
                      </TertiaryButton>
                    </div>
                  )}
                  {usePrintBauDoc && !mobile && (
                    <TertiaryButton
                      size={desktop ? 'small' : 'large'}
                      leftIcon={<StickyNoteIcon />}
                      onClick={() => setShowConstructionDocumentationPicker(true)}
                    >
                      {t('ORDERS.CONSTRUCTION_DOCUMENTATION.TITLE')}
                    </TertiaryButton>
                  )}
                  {!mobile && useOldOrders && (
                    <TertiaryButton
                      size={desktop ? 'small' : 'large'}
                      rightIcon={<ArrowForwardIcon />}
                      onClick={() => history.push(ShopRoutes.Orders)}
                    >
                      {t('ORDERS.NAVIGATE_TO_LEGACY_VIEW')}
                    </TertiaryButton>
                  )}
                </div>
              </div>
              <Divider />
              <div
                className={clsx(
                  styles.searchAndToolbar,
                  orders.length === 0 && styles.searchAndToolbarFlexDirectionColumn
                )}
              >
                <SearchField
                  className={styles.search}
                  placeholder={t('ORDERS.LIST.SEARCH.PLACEHOLDER')}
                  value={search}
                  onChange={setSearch}
                  onReset={() => setSearch('')}
                />
                <div className={styles.toolbar}>
                  <BasicDateRangePicker
                    mobile={mobile}
                    dateFrom={getOrderListFilterValue(history.location.search, 'dateFrom')}
                    dateTo={getOrderListFilterValue(history.location.search, 'dateTo')}
                    setDates={setDates}
                  />
                  <SecondaryIconButton icon={<FilterIcon />} onClick={() => setFiltersOpen(true)} />
                </div>
                {/* Permission to view orders, prior orders made, however NO results for current search/filter */}
                {!isLoading && orders.length === 0 && (
                  <NoResults
                    body={t('SEARCH.NO_RESULTS_EXTENDED.BODY')}
                    title={t('SEARCH.NO_RESULTS_EXTENDED.TITLE')}
                  />
                )}
              </div>
              <div className={styles.containerContent}>
                <OrderListVirtualized onLoadMoreOrders={onLoadMoreOrders} scrollTo={scrollTo} />
                {isLoading && <ListSkeletons />}
              </div>
            </Grid>
          </Grid>
        )}
      </div>
      {facets && (
        <MobileFilters
          amount={totalOrderCount}
          open={filtersOpen}
          sections={filterSections}
          onClose={onCloseMobileFilters}
          onSubmit={() => setFiltersOpen(false)}
        />
      )}
      {useOrdersFeedback && <Feedback id="orders-feedback" title={t('ORDERS.FEEDBACK')} />}
      {showConstructionDocumentationPicker && (
        <ConstructionDocumentationPicker
          open={showConstructionDocumentationPicker}
          searchResultCount={totalOrderCount}
          onDownload={onDownload}
          selectedOption={'small'}
          setShowConstructionDocumentationPicker={setShowConstructionDocumentationPicker}
        />
      )}
    </>
  )
}
export default OrdersListPage
