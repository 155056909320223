import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { useTranslation } from 'react-i18next'
import { OfferForDropdown } from '@obeta/models/lib/models/Offer/OfferV2'
import confirmStyles from './Confirm.module.scss'
import { SecondaryButton, TertiaryButton } from '../custom-button/CustomButton'
import styles from './SelectOfferConfirm.module.scss'

interface Props {
  handleConfirm: (chosenOffer: OfferForDropdown) => void
  handleCancel: () => void
  offersForSelect: OfferForDropdown[]
  openDialog: boolean
}
export const ConfirmWithOfferSelect: React.FC<Props> = (props) => {
  const { handleConfirm, handleCancel, offersForSelect, openDialog } = props
  const { t } = useTranslation()

  const theme = useTheme()

  const [chosenOffer, setChosenOffer] = useState(
    offersForSelect[0] ? offersForSelect[0] : { id: '', name: '' }
  )
  const handleOfferChosen = (event: SelectChangeEvent) => {
    const activeOffer = offersForSelect.find((offer) => offer.id === event.target.value)
    setChosenOffer(activeOffer ?? offersForSelect[0])
  }

  const currentCartOfferId: string = offersForSelect[0] ? offersForSelect[0].id : ''
  const currentCartOfferName: string = offersForSelect[0] ? offersForSelect[0].name : ''

  useEffect(() => {
    if (offersForSelect.length > 0) {
      setChosenOffer(offersForSelect[0])
    }
  }, [offersForSelect])

  const handleConfirmButtonClick = () => {
    handleConfirm(chosenOffer)
  }
  return (
    <Dialog
      className={confirmStyles.dialog}
      open={openDialog}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={confirmStyles.dialogTitle}>
        {t('SHOPPING_CART.CART_MOVE_ITEM_SELECT_OFFER')}
      </DialogTitle>
      <DialogContent className={confirmStyles.dialogContent}>
        <Typography variant={'body'} color={'secondary'}>
          {t('SHOPPING_CART.CART_MOVE_ITEM_OFFER_CONFLICT', {
            offerId: currentCartOfferId,
            offerName: currentCartOfferName,
          })}
        </Typography>
      </DialogContent>
      <Select
        onChange={handleOfferChosen}
        displayEmpty
        value={chosenOffer.id}
        className={styles.select}
        sx={{
          '.MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.text.primary },
        }}
      >
        {offersForSelect.map((offer) => {
          return (
            <MenuItem key={offer.id} value={offer.id} selected={offer.id === chosenOffer?.id}>
              <Typography variant={'body'} noWrap>
                {`Angebot ${offer.id} - ${offer.name}`}
              </Typography>
            </MenuItem>
          )
        })}
      </Select>
      <Divider variant="middle" className={confirmStyles.divider} />
      <DialogActions className={confirmStyles.dialogActions}>
        <TertiaryButton
          fullWidth={false}
          iconColor={'primary'}
          leftIcon={<CloseIcon />}
          onClick={handleCancel}
        >
          {t('SHOPPING_CART.ACTIONS.ABORT')}
        </TertiaryButton>
        <SecondaryButton
          fullWidth={false}
          iconColor={'success'}
          leftIcon={<CheckIcon />}
          onClick={handleConfirmButtonClick}
          autoFocus
        >
          {t('SHOPPING_CART.ACTIONS.CONFIRM')}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  )
}
