import { FC, ReactNode } from 'react'
import { Divider, Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './FormSection.module.scss'

export type FormSectionProps = {
  title: string | ReactNode
  children: ReactNode
  wrapperStyles?: string
}

export const FormSection: FC<FormSectionProps> = ({ title, children, wrapperStyles }) => {
  return (
    <div className={clsx(styles.wrapper, wrapperStyles)}>
      <Typography paragraph variant="bodyBold">
        {title}
      </Typography>
      <Divider className={clsx(styles.divider)} />
      {children}
    </div>
  )
}
