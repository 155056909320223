import { FC } from 'react'
import styles from './AcceptedFile.module.scss'
// import { ReactComponent as DeleteIcon } from '@obeta/assets/icon/designsystem/delete_outline.svg'
import { ReactComponent as ImageIcon } from 'assets/icon/designsystem/image.svg'
import { Typography } from '@mui/material'
// import { TertiaryResponsiveIconButton } from '../custom-button/CustomButton'

interface AcceptedFileProps {
  name: string
  onDelete: () => void
}

export const AcceptedFile: FC<AcceptedFileProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <ImageIcon className={styles.icon} />
        <Typography className={styles.name} variant="bodyBold">
          {props.name}
        </Typography>
      </div>
      {/*Reenable when deletion is solved https://coditorei.atlassian.net/browse/COD-19022 */}
      {/* <div className={styles.icon}>
        <TertiaryResponsiveIconButton icon={<DeleteIcon />} onClick={props.onDelete} />
      </div> */}
    </div>
  )
}
