import { ImageGallery } from '@obeta/components/lib/image-gallery'
import { IResponsiveComponentProps } from '@obeta/models/lib/models/Components'
import React, { ReactElement, useMemo, useState } from 'react'
import { IArticle } from './types'
import { ReactComponent as SettingsInputComposite } from '@obeta/assets/icon/settings_input_composite.svg'
import { ReactComponent as PrintIcon } from '@obeta/assets/icon/print.svg'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ImgGalleryPlaceholder } from '@obeta/assets/img/ImageGalleryPlaceholder.svg'
import { ContentItem, useArticleContentData } from '@obeta/data/lib/hooks/useArticleGalleryContent'
import styles from './Gallery.module.scss'
import { usePrintArticleDataSheet } from '@obeta/data/lib/hooks/usePrintArticleDataSheet'
import { ReactComponent as DopIcon } from '@obeta/assets/icon/designsystem/article.svg'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { LoadingIndicator } from '@obeta/components/lib/split-button/LoadingIndicator'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore from 'swiper'
import { Mousewheel, Scrollbar } from 'swiper/modules'
// Import Swiper styles
import '@obeta/assets/theme/swiper.scss'
import clsx from 'clsx'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { Stack } from '@mui/material'
import { submitElbridgePostFormToConfigurator } from '@obeta/utils/lib/elbridgeHelper'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { PrintConfigDatasheet } from '@obeta/components/lib/print-config-datasheet/PrintConfigDatasheet'
import { isAdmin } from '@obeta/utils/index'

SwiperCore.use([Scrollbar, Mousewheel])
interface IGalleryProps extends IResponsiveComponentProps {
  article: IArticle | undefined
  noRightOverFlowClass?: string
}

interface ActionElementConfig {
  label: string
  icon: ReactElement
  isPrintButton?: boolean
  onClick: () => Promise<void> | void
}

export const Gallery = React.memo<IGalleryProps>(function Gallery(props) {
  const { article } = props

  const { t } = useTranslation()
  const history = useHistory()
  const [isActive, setIsActive] = useState(true)
  const { print } = usePrintArticleDataSheet()
  const items = useArticleContentData(article)
  const { desktop, tabletWide } = useBreakpoints()

  const [isOpenIndividualPrintConfig, setIsOpenIndividualPrintConfig] = useState(false)

  const isIndividualDataSheetToggle = useFeatureToggle('UseIndividualDataSheetToggle')

  const elbridgeConfigurators = article?.elbridgeConfigurators || []

  const sapId = article?.sapId

  const user = useUserDataV2()
  const showDatasheet = user.permissions?.Global_canUseCustomDataSheet
  const { permissions, userId, companyId } = useUserDataV2()

  const isAdminUser = isAdmin({ userId, companyId })

  const imagesToShow = useMemo<ContentItem[]>(() => {
    if (items.length > 0) {
      return items
    }
    if (article?.supplierImageData.large)
      return [
        {
          contentType: 'image',
          thumbId: 'supplierLogo',
          thumbUrl: article?.supplierImageData.large,
          url: article?.supplierImageData.large,
        },
      ]
    return []
  }, [article?.supplierImageData.large, items])

  const actionConfig = [
    ...elbridgeConfigurators.map((c) => {
      return {
        icon: <SettingsInputComposite />,
        label: c.configuratorName,
        onClick: async () => {
          submitElbridgePostFormToConfigurator(c.configuratorUrl)
        },
      }
    }),
    user.isLoggedIn
      ? {
          icon: <PrintIcon />,
          label: 'ARTICLE_DETAIL.PRINT_DATA_SHIT',
          isPrintButton: true,
          onClick: async () => {
            if (isIndividualDataSheetToggle && showDatasheet) {
              setIsOpenIndividualPrintConfig(true)
              return
            }

            if (sapId) {
              setIsActive(false)
              try {
                await print(sapId)
                setIsActive(true)
              } catch (err) {
                setIsActive(true)
              }
            }
          },
        }
      : undefined,
    !isIndividualDataSheetToggle &&
    user.permissions?.Global_canUseCustomDataSheet &&
    isPlatform('web') //the custom data sheet is not yet available in the app
      ? {
          icon: <PrintIcon />,
          label: 'ARTICLE_DETAIL.PRINT_CUSTOM_DATA_SHEET',
          onClick: async () => {
            history.push(`/${ShopRoutes.CustomDatasheetPage}/${article?.obetaId}`)
          },
        }
      : undefined,
    (article?.dop?.length as number) > 1 && isPlatform('web') //the dop is not yet available in the app
      ? {
          icon: <DopIcon className={styles.dopIcon} />,
          label: 'ARTICLE_DETAIL.DOP',
          onClick: async () => {
            history.push(`/${ShopRoutes.DOP}/${article?.obetaId}`)
          },
        }
      : undefined,
    // TODO: uncomment these button when CTA for them will be defined
    // {
    //   icon: <MenuBookIcon />,
    //   label: 'ARTICLE_DETAIL.VIEW_STOCK_ASSORTMENT_CATALOGUE',
    //   onClick: () => {
    //     //
    //   },
    // },
    // {
    //   icon: <MenuBookIcon />,
    //   label: 'ARTICLE_DETAIL.SHOW_REDBOOK',
    //   onClick: () => {
    //     //
    //   },
    // },
  ]

  const createButtonItem = (conf: ActionElementConfig | undefined) => {
    if (!conf) {
      return null
    }
    const { label, icon, onClick, isPrintButton = false } = conf
    return (
      <TertiaryButton leftIcon={icon} disabled={!isActive} key={label} onClick={() => onClick()}>
        {t(label)}
        {isPrintButton && !isActive && <LoadingIndicator />}
      </TertiaryButton>
    )
  }

  const onPrintDatasheet = async (requestParamsUrl: string) => {
    if (sapId) {
      setIsActive(false)
      try {
        await print(sapId, requestParamsUrl)
        setIsActive(true)
      } catch (err) {
        setIsActive(true)
      }
    }
  }

  return (
    <div className={styles.root}>
      {imagesToShow?.length > 0 ? (
        <ImageGallery
          noRightOverFlowClass={props.noRightOverFlowClass}
          className={clsx(styles.gallery)}
          classes={{
            selectedImage: styles.selectedImage,
            selectedImageWrap: styles.selectedImageWrap,
            aspectRatioWrap: styles.aspectRatioWrap,
          }}
          items={imagesToShow}
        />
      ) : (
        <div className={clsx(styles.galleryPlaceholder, props.noRightOverFlowClass)}>
          <ImgGalleryPlaceholder />
        </div>
      )}

      <Swiper
        resizeObserver={true}
        className={styles.actions}
        direction="horizontal"
        slidesPerView={'auto'}
        freeMode={true}
        scrollbar={false}
        spaceBetween={24}
      >
        {desktop || tabletWide ? (
          <Stack alignItems={'flex-start'} gap="1.5rem">
            {actionConfig.map(createButtonItem)}
          </Stack>
        ) : (
          actionConfig.map((conf: ActionElementConfig | undefined) => {
            const item = createButtonItem(conf)

            if (!item) {
              return null
            }

            return (
              <SwiperSlide key={conf?.label} className={styles['btn-wrapper']}>
                {item}
              </SwiperSlide>
            )
          })
        )}
      </Swiper>
      {isOpenIndividualPrintConfig && (
        <PrintConfigDatasheet
          isOpen={isIndividualDataSheetToggle}
          onClose={() => setIsOpenIndividualPrintConfig(false)}
          onSubmit={onPrintDatasheet}
          isAdmin={isAdminUser}
          permissions={permissions}
        />
      )}
    </div>
  )
})
