export enum ESettingsSection {
  DATA_FOR_PRINT = 'Daten für individuelles Datenblatt',
  FIRM_DATA = 'Firmendaten',
  PRICE_DATA = 'Preisinformationen',
  DELIVERY_ADDRESSESS = 'Lieferadressen',
  COMMISSION = 'Kommission',
}

export type TSettingsView = Exclude<ESettingsSection, ESettingsSection.DATA_FOR_PRINT>

export interface IMenuItem {
  title: ESettingsSection
  options?: IMenuItem[]
}
