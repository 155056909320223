import { FC } from 'react'
import { Accept, FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './FileUpload.module.scss'
import { ReactComponent as UploadIcon } from 'assets/icon/designsystem/upload.svg'
import { ReactComponent as WarningIcon } from 'assets/icon/designsystem/warning.svg'
import { SecondaryButton } from '../custom-button/CustomButton'
import { AcceptedFile } from './AcceptedFile'

interface FileUploadProps {
  accept?: Accept
  buttonText?: string
  disabled?: boolean
  error?: string
  files: File[]
  hideAcceptedFiles?: boolean
  maxFiles?: number
  maxSize?: number
  title?: string
  disableWhiteContainer?: boolean
  onDeleteFile: (name: string) => void
  onDropAccepted: (acceptedFiles: File[]) => void
  onDropRejected: (fileRejections: FileRejection[]) => void
}

export const FileUpload: FC<FileUploadProps> = (props) => {
  const {
    accept,
    buttonText,
    disabled,
    disableWhiteContainer,
    error,
    files,
    hideAcceptedFiles,
    maxFiles,
    maxSize,
    onDeleteFile,
    onDropAccepted,
    onDropRejected,
  } = props

  const { t } = useTranslation()

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    disabled,
    onDropAccepted,
    onDropRejected,
    maxFiles: maxFiles ?? 1,
    maxSize,
  })

  return (
    <div className={clsx(styles.container, !disableWhiteContainer && styles.containerWhite)}>
      {!hideAcceptedFiles && (
        <div className={styles.acceptedFiles}>
          {files.map((file) => (
            <AcceptedFile
              key={file.name}
              name={file.name}
              onDelete={() => onDeleteFile(file.name)}
            />
          ))}
        </div>
      )}
      <div {...getRootProps({ className: styles.dropzone })}>
        <input {...getInputProps()} />
        <div className={styles.dropzoneInfo}>
          <UploadIcon className={styles.icon} />
          <Typography className={styles.text} variant="bodyBold">
            {t('FILE_UPLOAD.TEXT')}
          </Typography>
        </div>
        <SecondaryButton disabled={disabled}>
          {buttonText ?? t('FILE_UPLOAD.BUTTON')}
        </SecondaryButton>
      </div>
      {error && (
        <div className={styles.error}>
          <WarningIcon />
          <Typography variant="smallText">{error}</Typography>
        </div>
      )}
    </div>
  )
}
