import { trackClick } from './tracking'
import { OciFormField } from '@obeta/schema'

export const submitOciPostForm = (fields: OciFormField[], url: string, target: string) => {
  const ociForm = document.createElement('form')
  ociForm.method = 'POST'
  ociForm.action = url
  ociForm.target = target ?? '_top'
  let input = document.createElement('input')
  const track = []

  Object.entries(fields).forEach((field) => {
    input = document.createElement('input')
    input.type = 'hidden'
    input.name = field[1].name
    input.value = field[1].value
    ociForm.appendChild(input)
    track[input.name] = input.value
  })
  document.body.appendChild(ociForm)
  trackClick('oci-form-submit', Object.assign({}, track))
  ociForm.submit()
}
