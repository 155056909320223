import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { FormField } from './FormField'

const MAX_COMPANY_NAME_LENGTH = 35
const MAX_CONTACT_NAME_LENGTH = 60
const MAX_ADDRESS_LENGTH = 60
const MAX_CITY_LENGTH = 30
const ZIP_CODE_PATTERN = /^\d{5}$/i

export const CompanySection = () => {
  const { t } = useTranslation()

  const companyNameRules = {
    required: t('ADDRESSES.ERROR.COMPANY_REQUIRED'),
    maxLength: {
      value: MAX_COMPANY_NAME_LENGTH,
      message: t('ADDRESSES.ERROR.COMPANY_MAX_LENGTH'),
    },
  }

  const contactPersonRules = {
    required: t('UI.INPUT_EDIT_FIELD.ERRORS.REQUIRED'),
    maxLength: {
      value: MAX_CONTACT_NAME_LENGTH,
      message: t('UI.INPUT_EDIT_FIELD.ERRORS.MAX_LENGTH'),
    },
  }

  const zipCodeRules = {
    required: t('ADDRESSES.ERROR.ZIP_CODE_REQUIRED'),
    pattern: {
      value: ZIP_CODE_PATTERN,
      message: t('ADDRESSES.ERROR.ZIP_CODE_INVALID'),
    },
  }

  const addressRules = {
    required: t('ADDRESSES.ERROR.ADDRESS_REQUIRED'),
    maxLength: {
      value: MAX_ADDRESS_LENGTH,
      message: t('ADDRESSES.ERROR.ADDRESS_INVALID'),
    },
  }

  const cityRules = {
    required: t('ADDRESSES.ERROR.CITY_REQUIRED'),
    maxLength: {
      value: MAX_CITY_LENGTH,
      message: t('ADDRESSES.ERROR.CITY_INVALID'),
    },
  }

  return (
    <Grid container columnSpacing={1} rowSpacing={{ xs: 1, sm: 1.5 }}>
      <Grid item xs={12} sm={6}>
        <FormField
          name="companyName"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.COMPANY_NAME')}
          rules={companyNameRules}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          name="contactPerson"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.CONTACT_PERSON')}
          placeholder={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.CONTACT_PERSON_PLACEHOLDER')}
          rules={contactPersonRules}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormField
          name="streetAndNumber"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.ADDRESS')}
          rules={addressRules}
        />
      </Grid>
      <Grid item xs={4} sm={2}>
        <FormField
          name="zipCode"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.ZIP_CODE')}
          rules={zipCodeRules}
        />
      </Grid>
      <Grid item xs={8} sm={4} paddingLeft="0.5rem !important">
        <FormField
          name="city"
          label={t('SETTINGS.FIRM_DATA_SECTION.FIELDS.CITY')}
          rules={cityRules}
        />
      </Grid>
    </Grid>
  )
}
