import { Card, IconButton, Link, PopoverOrigin, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { memo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addOrReplaceOfferInCartGraphQL,
  AddOrReplaceOfferInCartGraphQLResultAction,
  CartsActionTypes,
} from '@obeta/data/lib/actions'
import brokenimage from 'assets/img/shoppingcartoverview/broken_image.svg'
import { ReactComponent as AddShoppingCartIcon } from '@obeta/assets/icon/designsystem/add_shopping_cart.svg'
import { ReactComponent as AddToReminderListIcon } from 'assets/icon/designsystem/add_to_reminderlist.svg'
import { ReactComponent as FavoriteIcon } from 'assets/icon/designsystem/favourite.svg'
import { ReactComponent as FavoriteBorderIcon } from 'assets/icon/designsystem/favourite_border.svg'
import { ReactComponent as MoreHorizIcon } from 'assets/icon/designsystem/more_horiz.svg'
import { ReactComponent as TrashIcon } from 'assets/icon/designsystem/delete_outline.svg'

import { ConfirmWithOfferSelect } from '../alert-and-confirm/ConfirmWithOfferSelect'
import { OfferFlagItem } from './OfferFlagItem'
import { OfferListItemMetaDataItem } from './OfferListItemMetaDataItem'
import { ProductOxomiImage } from '../product-images/ProductOxomiImage'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useDispatch } from 'react-redux'
import { useEntities } from '@obeta/data/lib/hooks/useEntities'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { useOfferActions } from '@obeta/data/lib/hooks/useOfferActions'
import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'
import { useActionNotification, useSelectShoppingCart } from '@obeta/data/lib/hooks'
import {
  ShoppingCartForDropdown,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { OfferForDropdown } from '@obeta/models/lib/models'
import {
  OfferForListPage,
  OfferItemsForListPage,
  OfferItemsItemsForListPage,
} from '@obeta/models/lib/schema-models/offer-list'
import { UpdateOfferMetaDataInput } from '@obeta/schema'
import styles from './OfferListItemV2.module.scss'
import { DropdownCartsBase } from '../dropdown-carts/DropdownCarts'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { SplitButton } from '../split-button/SplitButton'
import { normalizePrice } from '@obeta/utils/lib/data-formatter'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { trackCustom } from '@obeta/utils/lib/tracking'
import { ImgProxyImage } from '../img-proxy-image/ImgProxyImage'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { ensureValidImgProxyUrl } from '@obeta/utils/lib/ensureValidImgProxyUrl'
import { useSessionCartName } from '@obeta/data/lib/hooks/useSessionCartName'
import { PrimaryButton } from '../custom-button/CustomButton'
import { AnimatedCartButton } from '../add-items-to-cart-button/AnimatedCartButton'
import {
  EButtonState,
  useAnimatedButtonState,
} from '../add-items-to-cart-button/AnimatedCartButtonContext'

const IMAGE_GRID_SIZE = 10

type Props = {
  notPurchasable: boolean
  offer: OfferForListPage
  offerItems?: OfferItemsForListPage
  carts: ShoppingCartForDropdown[]
  onToggleFavorite: (id: string, isFavorite: boolean) => void
  setOfferToShowCancellationDialogFor: (id: string) => void
}

export const OfferListItemV2 = memo(function OfferListItemV2(props: Props) {
  const {
    notPurchasable,
    offer,
    offerItems,
    carts,
    onToggleFavorite,
    setOfferToShowCancellationDialogFor,
  } = props

  // Component state
  const [cartIdToShowConflictFor, setCartIdToShowConflictFor] = useState('')
  const [showOfferConflictDialog, setShowOfferConflictDialog] = useState(false)
  const [isFavorite, setIsFavorite] = useState<boolean>(props.offer.isFavorite)
  const [offersForDropdown, setOffersForDropdown] = useState<OfferForDropdown[]>([])
  const [anchorElement, setAnchorElement] = useState<null | Element>(null)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { setAnimationState: setButtonState, animationState } = useAnimatedButtonState()

  const splitButtonRef = useRef<HTMLDivElement>(null)
  const waitForAction = useActionNotification(CartsActionTypes.AddOrReplaceOfferInCartGraphQLResult)
  const isAddToCartProcessIndicator = useFeatureToggle('useAddToCartProcessIndicator')

  const { mobile } = useBreakpoints()
  const shoppingCarts = useEntities<ShoppingCartV2>('cartsv2')
  const popoverState = usePopoverState(splitButtonRef)
  const { t } = useTranslation()
  const userV2 = useUserV2()
  const history = useHistory()
  const dispatch = useDispatch()
  const { updateOfferMetaData } = useOfferActions()
  const onSetActiveCart = useSelectShoppingCart()

  const defaultCartId = userV2?.settings?.defaultCartId ?? ''
  const defaultCart = carts.find((cart) => cart.id === defaultCartId)
  const useImgProxy = useFeatureToggle('UseImgProxy')
  const { getSessionCartName, isSessionCart } = useSessionCartName()

  const onOpenTemplatesDropdown = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget)
    setIsDropdownOpen(true)
  }

  const onAddOfferToCart = (cart) => {
    // Check for conflict
    if (cart?.offerId !== '' && cart?.offerId !== offer.offerId) {
      setOffersForDropdown([
        { id: cart?.offerId ?? '', name: cart?.offerName ?? '' },
        { id: offer.offerId, name: offer.offerName },
      ])
      setCartIdToShowConflictFor(cart.id)
      setShowOfferConflictDialog(true)
    } else {
      trackCustom('on-add-offer-to-cart', {
        cartId: cart.id,
        itemCount: offer.itemCount,
        offerName: offer.offerName,
        offerId: offer.offerId,
      })
      if (isAddToCartProcessIndicator) {
        setButtonState(EButtonState.Loading)
      }
      dispatch(
        addOrReplaceOfferInCartGraphQL(
          offer.offerId,
          offer.offerName,
          offer.itemCount,
          cart.id,
          true,
          [],
          [],
          [],
          null
        )
      )
      if (isAddToCartProcessIndicator) {
        waitForAction((action: AddOrReplaceOfferInCartGraphQLResultAction) => {
          setButtonState(action.success ? EButtonState.Success : EButtonState.Error)
        })
      }
      onSetActiveCart(cart.id)
    }
  }

  /**
   * Handler to update offer favorite state.
   * @param input UpdateOfferMetaData mutation input
   */
  const onToggleFavoriteInit = async (input: UpdateOfferMetaDataInput) => {
    const isUpdated = await updateOfferMetaData(input)
    // Update favorite state on success
    if (isUpdated) {
      setIsFavorite(input.isFavorite)
      onToggleFavorite(input.offerId, input.isFavorite)
    }
  }

  const handleOfferConflictDialogConfirmed = (chosenOffer: OfferForDropdown) => {
    if (chosenOffer.id === offer.offerId) {
      // Displayed offer chosen - offer relation in cart should be replaced/overwritten
      dispatch(
        addOrReplaceOfferInCartGraphQL(
          offer.offerId,
          offer.offerName,
          offer.itemCount,
          cartIdToShowConflictFor,
          true,
          [],
          [],
          [],
          null
        )
      )
      onSetActiveCart(cartIdToShowConflictFor)
    } else {
      // Existing offer in cart chosen - offer relation in cart should - not - be replaced/overwritten
      dispatch(
        addOrReplaceOfferInCartGraphQL(
          offer.offerId,
          offer.offerName,
          offer.itemCount,
          cartIdToShowConflictFor,
          false,
          [],
          [],
          [],
          null
        )
      )
      onSetActiveCart(cartIdToShowConflictFor)
    }
    setCartIdToShowConflictFor('')
    setShowOfferConflictDialog(false)
    setOffersForDropdown([])
  }
  const handleOfferConflictDialogCancelled = () => {
    setShowOfferConflictDialog(false)
    setOffersForDropdown([])
  }

  const offerNameAndItemCountGrid: JSX.Element = (
    <Grid direction="column">
      <Typography className={styles.name} variant="boldText">
        {`${offer.offerName} (${offer.itemCount})`}
      </Typography>
    </Grid>
  )

  const anchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' }
  const transformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' }

  const onCloseTemplatesDropdown = () => {
    setIsDropdownOpen(false)
  }

  function getOfferItemImageForDisplay(item: OfferItemsItemsForListPage | undefined): JSX.Element {
    if (!item) {
      return <div />
    }

    const isCustomArticle = item.product.type === 'custom'

    if (isCustomArticle) {
      // Show supplier image for custom item
      if (item.supplierImageData?.large) {
        return (
          <img
            alt={`${item.product.sapId}|${item.offerItemPosition}`}
            className={styles.imageGridItem}
            src={item.supplierImageData.large}
          />
        )
      }
    } else {
      // Show product image for regular item
      if (
        item.product?.images &&
        item.product?.imageData &&
        item.product.imageData.images[0]?.large &&
        item.product?.oxomiId &&
        item.product?.sapId &&
        item.product?.supplierId
      ) {
        const validUrl = ensureValidImgProxyUrl(item.product?.images?.[0]?.url)
        return useImgProxy && validUrl ? (
          <ImgProxyImage
            className={styles.imageGridItem}
            url={validUrl}
            width={item.product.images[0].width ?? undefined}
            title={item.product.title}
            mobileWidthRem={1}
            tabletWidthRem={1}
            tabletWideWidthRem={1}
            desktopWidthRem={1}
          />
        ) : (
          <ProductOxomiImage
            alt={`${item.product.sapId}|${item.offerItemPosition}`}
            src={item.product.imageData.images[0].large}
            oxomiId={item.product.oxomiId}
            supplierId={item.product.supplierId}
          />
        )
      }
      // Show supplier image for regular item
      else if (item.product.supplierImageData?.large) {
        return (
          <img
            alt={`${item.product.sapId}|${item.offerItemPosition}`}
            className={styles.imageGridItem}
            src={item.product.supplierImageData?.large}
          />
        )
      }
    }

    // Show fallback image for either regular or custom items
    return (
      <img
        alt={`${item.product.sapId}|${item.offerItemPosition}`}
        className={styles.imageGridItem}
        src={brokenimage}
      />
    )
  }

  const offerDataGrid = (
    <Grid container direction="column">
      <OfferListItemMetaDataItem
        title={t('OFFERS.META_DATA.ID')}
        value={offer.offerId.toString()}
      />
      <OfferListItemMetaDataItem
        title={t('OFFERS.META_DATA.CUSTOMER_REFERENCE')}
        value={offer.customerReference}
      />
      <OfferListItemMetaDataItem
        title={t('OFFERS.META_DATA.NET_PRICE')}
        value={normalizePrice(offer.netPrice)}
      />
      <OfferListItemMetaDataItem
        title={t('OFFERS.META_DATA.START_DATE')}
        value={dateFormatter(offer.startDate)}
      />
      <OfferListItemMetaDataItem
        title={t('OFFERS.META_DATA.LAST_UPDATED')}
        value={dateFormatter(offer.lastUpdated)}
      />
      <OfferListItemMetaDataItem
        title={t('OFFERS.META_DATA.END_DATE')}
        value={dateFormatter(offer.endDate)}
      />
      <Grid>
        <div className={styles.imageGrid}>
          {offerItems?.items.slice(0, IMAGE_GRID_SIZE).map((item) => (
            <div
              key={`${item.product.sapId}|${item.offerItemPosition}`}
              className={styles.imageGridItem}
            >
              {getOfferItemImageForDisplay(item)}
            </div>
          ))}
          {offerItems && offerItems?.items.length > IMAGE_GRID_SIZE && (
            <div className={styles.imageGridItem}>
              <MoreHorizIcon />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )

  let cartName = '-'
  if (defaultCart) {
    cartName = isSessionCart(defaultCart) ? getSessionCartName(defaultCart) : defaultCart.name
  }

  const disabledCartButton =
    !offer.isActive || notPurchasable || offer.offerFlags.includes('expired')

  const cartButtonGrid = (
    <Grid className={styles.cartButton}>
      {isAddToCartProcessIndicator ? (
        <AnimatedCartButton
          ref={splitButtonRef}
          disabled={disabledCartButton}
          cartsLength={carts.length}
          buttonState={animationState}
          title={cartName}
          onArrowDownClicked={popoverState.handleClick}
          onClick={() => {
            onAddOfferToCart(defaultCart)
          }}
          size={mobile ? 'large' : 'small'}
        />
      ) : carts.length > 1 ? (
        <SplitButton
          disabled={disabledCartButton}
          loading={false}
          size={mobile ? 'large' : 'small'}
          title={cartName}
          onArrowDownClicked={popoverState.handleClick}
          onClick={(event) => {
            event.preventDefault()
            onAddOfferToCart(defaultCart)
          }}
        />
      ) : (
        <PrimaryButton
          disabled={disabledCartButton}
          leftIcon={<AddShoppingCartIcon />}
          size={mobile ? 'large' : 'small'}
          onClick={(event) => {
            event.preventDefault()
            onAddOfferToCart(defaultCart)
          }}
        >
          {cartName}
        </PrimaryButton>
      )}
      <DropdownCartsBase
        carts={shoppingCarts.map((shoppingCart) => {
          return { ...shoppingCart, count: shoppingCart.items.length }
        })}
        mobile={mobile}
        onOptionSelected={(cart) => {
          onAddOfferToCart(cart)
        }}
        dropdown={{
          anchorEl: mobile ? null : popoverState.anchorEl,
          onClose: popoverState.onClose,
          open: popoverState.open,
        }}
      />
      <ConfirmWithOfferSelect
        handleConfirm={handleOfferConflictDialogConfirmed}
        handleCancel={handleOfferConflictDialogCancelled}
        offersForSelect={offersForDropdown}
        openDialog={showOfferConflictDialog}
      />
    </Grid>
  )

  return (
    <Grid className={styles.item} size={{ xs: 12, sm: 6, lg: 4 }}>
      <Card className={styles.card} elevation={1}>
        <div className={styles.wrap}>
          <div className={styles.header}>
            <div className={styles.flags}>
              {offer.offerFlags.map((flag) => (
                <OfferFlagItem key={flag} flag={flag} />
              ))}
            </div>
            <div className={styles.actions}>
              <IconButton
                className={styles.actionsItem}
                color={isFavorite ? 'primary' : 'secondary'}
                disableRipple
                disableFocusRipple
                onClick={() =>
                  onToggleFavoriteInit({ isFavorite: !isFavorite, offerId: offer.offerId })
                }
              >
                {isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
              </IconButton>
              <IconButton
                className={styles.actionsItem}
                color={'secondary'}
                disableRipple
                disableFocusRipple
                onClick={() => setOfferToShowCancellationDialogFor(offer.offerId)}
              >
                {<TrashIcon />}
              </IconButton>
              <IconButton
                className={styles.actionsItem}
                color="secondary"
                disableRipple
                disableFocusRipple
                onClick={onOpenTemplatesDropdown}
              >
                <AddToReminderListIcon />
              </IconButton>
              <DropdownTemplatesBase
                dropdown={{
                  anchorEl: anchorElement,
                  anchorOrigin,
                  transformOrigin,
                  open: isDropdownOpen,
                  onClose: onCloseTemplatesDropdown,
                }}
                mobile={mobile}
                offer={{
                  offerId: offer.offerId,
                  offerName: offer.offerName,
                  selectedItemCount: offer.itemCount,
                  modifiedOfferItemAmounts: [],
                  multiSelection: {
                    selectAll: true,
                    include: [],
                    exclude: [],
                    search: {
                      searchTerm: '',
                      filter: '',
                      orderBy: '',
                      orderDir: '',
                    },
                  },
                }}
                productsToAdd={[
                  {
                    productId: '',
                    amount: 0,
                  },
                ]}
                templatesType={DropdownTemplatesType.ADD_OFFER}
              />
            </div>
          </div>
          <LightGrayDivider />
          <Grid className={styles.content} container direction="column">
            {offer.isActive && !offer.offerFlags.includes('expired') ? (
              <>
                <Link
                  href={`/offer-details/${offer.offerId}`}
                  color={'inherit'}
                  underline={'none'}
                  maxWidth={'100%'}
                  onClick={(event) => {
                    event.preventDefault()
                    history.push(`/offer-details/${offer.offerId}`)
                  }}
                >
                  {offerNameAndItemCountGrid}
                  {offerDataGrid}
                </Link>
                {cartButtonGrid}
              </>
            ) : (
              <>
                {offerNameAndItemCountGrid}
                {offerDataGrid}
                {cartButtonGrid}
              </>
            )}
          </Grid>
        </div>
      </Card>
    </Grid>
  )
})
