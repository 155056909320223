import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { ReactComponent as CheckIcon } from 'assets/icon/designsystem/check.svg'
import { SecondaryButton } from '../custom-button/CustomButton'
import styles from './Alert.module.scss'
import { useTranslation } from 'react-i18next'

interface Props {
  heading: string
  body?: string
  handleConfirm: () => void
  openAlertDialog: boolean
}

export const Alert: React.FC<Props> = (props) => {
  const { heading, body, handleConfirm, openAlertDialog } = props
  const { t } = useTranslation()

  return (
    <Dialog
      className={styles.dialog}
      open={openAlertDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={styles.dialogTitle}>
        {heading}
      </DialogTitle>
      {body && (
        <DialogContent className={styles.dialogContent}>
          <Typography variant={'body'} color={'secondary'}>
            {body}
          </Typography>
        </DialogContent>
      )}
      <LightGrayDivider className={styles.divider} />
      <DialogActions className={styles.dialogActions}>
        <SecondaryButton
          fullWidth={false}
          iconColor={'success'}
          leftIcon={<CheckIcon />}
          onClick={handleConfirm}
          autoFocus
        >
          {t('SHOPPING_CART.ACTIONS.CONFIRM')}
        </SecondaryButton>
      </DialogActions>
    </Dialog>
  )
}
