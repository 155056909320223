import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DarkButton, TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import styles from './SettingsPage.module.scss'

export const FormActionFooter = ({ onSubmit, onReset }) => {
  const { t } = useTranslation()
  const {
    formState: { isDirty, isLoading, errors, isSubmitting },
  } = useFormContext()

  return (
    <div className={styles.formActionFooter}>
      <TertiaryButton disabled={!isDirty || isLoading} onClick={onReset}>
        {t('BUTTON.ABORT')}
      </TertiaryButton>
      <DarkButton
        type="submit"
        disabled={!isDirty || isLoading || !!Object.keys(errors).length || isSubmitting}
        onClick={onSubmit}
      >
        {t('BUTTON.SAVE_DATA')}
      </DarkButton>
    </div>
  )
}
