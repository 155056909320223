import React, { FC } from 'react'
import { Typography } from '@mui/material'
import { SearchField } from '../search-field/SearchField'
import styles from './OverviewPageHeader.module.scss'

type Props = {
  title: string
  noSearchField?: boolean
  titleLineActions: Array<JSX.Element>
  searchPlaceHolder: string
  searchTerm: string
  setSearchTerm: (value: string) => void
}

export const OverviewPageHeader: FC<Props> = ({
  title,
  titleLineActions,
  noSearchField,
  searchTerm,
  setSearchTerm,
  searchPlaceHolder,
}) => {
  return (
    <div>
      <div className={styles.titleLine}>
        <Typography className={styles.titleText} variant="h3" color="text.primary">
          {title}
        </Typography>

        {titleLineActions}
      </div>

      {!noSearchField && (
        <div className={styles.searchWrapper}>
          <SearchField
            id="searchBox"
            placeholder={searchPlaceHolder}
            value={searchTerm}
            onChange={setSearchTerm}
            onReset={() => setSearchTerm('')}
          />
        </div>
      )}
    </div>
  )
}
