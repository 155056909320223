import { Dispatch, FC, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { SectionHeader } from '@obeta/components/index'
import { NavMenu } from './NavMenu'
import { ESettingsSection, TSettingsView } from './types'
import styles from './Header.module.scss'

export interface IHeader {
  activeView: TSettingsView
  setActiveView: Dispatch<SetStateAction<TSettingsView>>
}

export const Header: FC<IHeader> = ({ activeView, setActiveView }) => {
  const { t } = useTranslation()

  const subtitles: Partial<Record<TSettingsView, string>> = {
    [ESettingsSection.FIRM_DATA]: t('SETTINGS.SUBTITLE.FIRM_DATA'),
    [ESettingsSection.PRICE_DATA]: t('SETTINGS.SUBTITLE.PRICE_DATA'),
  }

  const descriptions: Record<TSettingsView, string> = {
    [ESettingsSection.FIRM_DATA]: t('SETTINGS.DESCRIPTION.FIRM_DATA'),
    [ESettingsSection.PRICE_DATA]: t('SETTINGS.DESCRIPTION.PRICE_DATA'),
    [ESettingsSection.DELIVERY_ADDRESSESS]: t('SETTINGS.DESCRIPTION.DELIVERY_ADDRESSESS'),
    [ESettingsSection.COMMISSION]: t('SETTINGS.DESCRIPTION.COMMISSION'),
  }

  const hasSubtitle =
    activeView === ESettingsSection.FIRM_DATA || activeView === ESettingsSection.PRICE_DATA

  return (
    <>
      <SectionHeader marginBottom="md">{t('SETTINGS.TITLE')}</SectionHeader>
      <NavMenu setActiveView={setActiveView} activeView={activeView} />
      {hasSubtitle && (
        <Typography paragraph className={styles.subtitle} variant="boldText">
          {subtitles[activeView]}
        </Typography>
      )}
      <Typography paragraph variant="body1">
        <Trans i18nKey={`SETTINGS.DESCRIPTION.${activeView}`}>{descriptions[activeView]}</Trans>
      </Typography>
    </>
  )
}
