import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PersonAdd } from 'assets/icon/designsystem/person_add.svg'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { UserOverviewProvider } from '@obeta/data/lib/stores/useUserOverviewContext'
import { useUserOverviewTableData } from '@obeta/data/lib/hooks/user-overview/useUserOverviewTableData'
import { withProvider } from '@obeta/data/lib/providers/withProvider'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { Feedback } from '@obeta/components/lib/feedback/Feedback'
import { CreateUserDialog } from './create-user/CreateUserDialog'
import { CreateUserProvider } from './create-user/useCreateUserContext'
import { UserOverviewTable } from './UserOverviewTable'
import { UserManagementSkeleton } from '../userManagementPage/UserManagementSkeleton'
import { OverviewPageHeader } from '@obeta/components/lib/overview-page-header/OverviewPageHeader'

export const UserOverviewPage: FC = withProvider(() => {
  const { t } = useTranslation()
  const { desktop, mobile } = useBreakpoints()
  const {
    sortedItems: users,
    setSearchText,
    searchText,
    loading,
    refetchUsers,
    unfilteredUsers,
  } = useUserOverviewTableData()
  const [isNewUserDialogShown, setIsNewUserDialogShown] = useState(false)
  const { user } = useUserDataV2()
  const loggedInUser = unfilteredUsers.find(
    (subuser) => subuser.customerNumber === user?.customerNumber
  )

  if (loading) return <UserManagementSkeleton />

  return (
    <>
      {isNewUserDialogShown && (
        <CreateUserProvider>
          <CreateUserDialog
            open={isNewUserDialogShown}
            onClose={() => setIsNewUserDialogShown(false)}
            refetchUsers={refetchUsers}
          />
        </CreateUserProvider>
      )}
      <div>
        <OverviewPageHeader
          title={t('USER_MANAGEMENT.USER_OVERVIEW')}
          titleLineActions={[
            <TertiaryButton
              leftIcon={<PersonAdd />}
              onClick={() => setIsNewUserDialogShown(true)}
              size={desktop ? 'small' : 'large'}
            >
              {!mobile && t('USER_MANAGEMENT.CREATE_NEW_USER')}
            </TertiaryButton>,
          ]}
          searchPlaceHolder={t('USER_MANAGEMENT.SEARCH_FIELD_PLACE_HOLDER')}
          searchTerm={searchText}
          setSearchTerm={setSearchText}
        />

        <UserOverviewTable users={users} loggedInUser={loggedInUser} />
      </div>
      <Feedback id="user-management-feedback" title={t('USER_MANAGEMENT.FEEDBACK')} />
    </>
  )
}, UserOverviewProvider)
