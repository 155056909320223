import { FC } from 'react'
import { ShopLink } from '../link/ShopLink'

const RESPONSE_LINK_MAP = {
  CART_PAGE_1: '/shopping-cart-details/:cartId',
}

type DynamicParameterKey = ':cartId'
type MarkdownPayload = Record<DynamicParameterKey, string>

function applyPayload(input: string, payload: MarkdownPayload): string {
  let result = input
  Object.entries(payload).forEach(([key, value]) => {
    result = result.replace(key, value)
  })
  return result
}

function transformMarkdown(markdown: string, payload?: MarkdownPayload) {
  const linkRegex = /\[([^\]]+)\]\(([^\s)]+)\)/g
  const parts: JSX.Element[] = []
  let lastIndex = 0

  markdown.replace(linkRegex, (match, text, key, offset) => {
    // add preceding text
    if (offset > lastIndex) {
      parts.push(<span key={lastIndex}>{markdown.slice(lastIndex, offset)}</span>)
    }
    // add ShopLink
    const mappedLink = RESPONSE_LINK_MAP[key]
    parts.push(
      <ShopLink key={offset} href={payload ? applyPayload(mappedLink, payload) : mappedLink}>
        {text}
      </ShopLink>
    )
    lastIndex = offset + match.length
    return match
  })

  // add any remaining text
  if (lastIndex < markdown.length) {
    parts.push(<span key={lastIndex}>{markdown.slice(lastIndex)}</span>)
  }

  return parts
}

/**
 * @param input raw markdown string
 * @param payload map dynamic markdown contents i.e. a shopping cart link that needs a dynamic id { ':cartId': dynamicCartId}
 */
export const MarkdownTransformer: FC<{ input: string; payload?: MarkdownPayload }> = ({
  input,
  payload,
}) => <div>{transformMarkdown(input, payload)}</div>
