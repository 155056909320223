import { Box, ButtonBase, Dialog, Stack, Typography } from '@mui/material'
import { LightGrayDivider } from '@obeta/components/lib/light-gray-divider/LightGrayDivider'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ArticleDetailsSections } from '@obeta/utils/lib/variables'
import styles from './ArticleDescription.module.scss'
import { ReactComponent as BackArrow } from 'assets/icon/designsystem/arrow_back.svg'
import { ReactComponent as ChevRight } from 'assets/icon/designsystem/chevron_right.svg'
import { SectionWrapper } from './SectionProvider'
import { ToggleMore } from '@obeta/components/lib/toggle-more/ToggleMore'
import clsx from 'clsx'
import { SectionHeader } from './SectionHeader'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useTheme } from '@mui/system'
import { useObetaResizeObserver } from '@obeta/data/lib/hooks/useObetaResizeObserver'

interface IArticleDescriptionProps {
  description: string
  catalogDescription: string
}

export const ArticleDescription: React.FC<IArticleDescriptionProps> = (props) => {
  const { description, catalogDescription } = props
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)
  const [canToggle, setCanToggle] = useState(false)
  const descriptionRef = useRef<HTMLDivElement>(null)
  const { mobile } = useBreakpoints()
  const theme = useTheme()

  useObetaResizeObserver(descriptionRef, (entry) => {
    const container = entry.target as HTMLElement
    const canToggle = container.scrollHeight > container.offsetHeight
    setCanToggle(canToggle)
  })

  return (
    <SectionWrapper
      available={Boolean(description)}
      id={ArticleDetailsSections.Description}
      className={styles.root}
    >
      <SectionHeader>{t('ARTICLE_DETAIL.DESCRIPTION')}</SectionHeader>
      <LightGrayDivider />
      <div ref={descriptionRef} className={styles.descriptionVirtual}>
        <Typography variant="body1">{description}</Typography>
        <br />
        <Typography>{catalogDescription}</Typography>
      </div>
      <div className={clsx({ [styles.shortenedDescription]: !showMore || mobile })}>
        <Typography>{description}</Typography>
        <br />
        <Typography>{catalogDescription}</Typography>
      </div>
      {!mobile && <LightGrayDivider />}
      {canToggle &&
        (mobile ? (
          <ButtonBase onClick={() => setShowMore(!showMore)}>
            <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
              <Typography variant="bodyBold">{t('MAIN.SHOW_MORE')}</Typography>
              <ChevRight />
            </Stack>
          </ButtonBase>
        ) : (
          <ToggleMore
            canToggle={canToggle}
            showMore={showMore}
            toggleMore={() => {
              setShowMore((baseState) => !baseState)
            }}
          />
        ))}
      {mobile && (
        <Dialog open={showMore} onClose={() => setShowMore(false)} fullScreen>
          <Stack
            padding="calc(1rem + var(--obeta-safe-area-top-root)) 1.5rem 1.5rem 1.5rem"
            gap="1rem"
          >
            <Stack
              direction={'row'}
              justifyContent={'flex-start'}
              paddingBottom={'1rem'}
              borderBottom={`1px solid ${theme.palette.grayVariant.light}`}
            >
              <Box sx={{ position: 'absolute' }}>
                <TertiaryButton
                  size="small"
                  onClick={() => setShowMore(false)}
                  leftIcon={<BackArrow />}
                >
                  zurück
                </TertiaryButton>
              </Box>
              <Typography flex="1" textAlign={'center'} variant="bodyBold">
                Beschreibung
              </Typography>
            </Stack>
            <Typography>{description}</Typography>
          </Stack>
        </Dialog>
      )}
    </SectionWrapper>
  )
}
