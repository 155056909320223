import { Card, Typography } from '@mui/material'
import { LightGrayDivider } from '../light-gray-divider/LightGrayDivider'
import { useState } from 'react'
import { ReactComponent as DownloadIcon } from 'assets/icon/designsystem/download.svg'
import styles from './ShippingDetails.module.scss'
import { SecondaryButton, TertiaryButton, TertiaryIconButton } from '../custom-button/CustomButton'
import { useTranslation } from 'react-i18next'
import { dateFormatter } from '@obeta/utils/lib/dateFormatter'
import { OrderStateItem } from '../orders/OrderStateItem'
import { useDispatchInvoices } from '@obeta/data/lib/hooks/useDispatchInvoice'
import { useDispatchDeliverySlip } from '@obeta/data/lib/hooks/useDispatchDeliverySlip'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'
import { ReactComponent as PackageIcon } from 'assets/icon/designsystem/package.svg'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'
import { PackageTrackingInfo } from '@obeta/schema'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { PopoverFromBottom } from '../popover/PopoverFromBottom'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { ReactComponent as ArrowDownIcon } from '@obeta/assets/icon/arrow_down1.svg'
import { Dropdown2 } from '../dropdown2/Dropdown2'

export const ShippingDetails = (props) => {
  const { mobile } = useBreakpoints()
  const user = useUserDataV2()
  const { t } = useTranslation()
  const { orderShippingDetails } = props
  const { dispatchDeliverySlipPdf } = useDispatchDeliverySlip()
  const { dispatchInvoicePdf } = useDispatchInvoices()
  const popoverState = usePopoverState()

  const [downloadMenuOpen, setDownloadMenuOpen] = useState<boolean>(false)

  const usePackageTrackingInfos = useFeatureToggle('UsePackageTrackingInfos')
  const { createdAt, deliverySlipId, invoiceId, deliverySlipPrintable, orderState, packages } =
    orderShippingDetails

  const packageTrackingInfos =
    usePackageTrackingInfos && orderShippingDetails.packageTrackingInfos?.length > 0
      ? orderShippingDetails.packageTrackingInfos
      : null

  const isSinglePackageTrackingLink = packageTrackingInfos && packageTrackingInfos.length === 1

  const isDeliverySlipPrintable = deliverySlipId && deliverySlipPrintable
  const isInvoiceAvailable =
    invoiceId && user?.permissions?.Global_canReadPrices !== PricePermissions.NoPrice
  const isDownloadAvailable = isDeliverySlipPrintable || isInvoiceAvailable

  const deliverySlipButton = (
    <TertiaryButton
      leftIcon={<DownloadIcon />}
      onClick={() => dispatchDeliverySlipPdf(deliverySlipId)}
    >
      {t('ORDERS.SHIPPING_DETAILS.DELIVERY_SLIP')}
    </TertiaryButton>
  )

  const invoiceButton = (
    <TertiaryButton leftIcon={<DownloadIcon />} onClick={() => dispatchInvoicePdf(invoiceId)}>
      {t('ORDERS.SHIPPING_DETAILS.INVOICE')}
    </TertiaryButton>
  )

  return (
    <Card className={styles.card} elevation={1}>
      <div className={styles.titleAndDataItems}>
        <div className={styles.titleAndLabelWrapper}>
          {(deliverySlipId || invoiceId) && (
            <Typography variant="bodyBold">
              {t('ORDERS.SHIPPING_DETAILS.DELIVERY')} {deliverySlipId || invoiceId}
            </Typography>
          )}
          {orderState && <OrderStateItem key={orderState.type} itemState={orderState} />}
        </div>
        <div className={styles.dataItemsWrapper}>
          {createdAt && (
            <div className={styles.dataItems}>
              <Typography variant="body">{t('ORDERS.SHIPPING_DETAILS.DATE')}</Typography>
              <Typography variant="bodyBold">{dateFormatter(createdAt)}</Typography>
            </div>
          )}
          {packages && <LightGrayDivider orientation="vertical" />}
          {packages && (
            <div className={styles.dataItems}>
              <Typography variant="body">
                {t('ORDERS.SHIPPING_DETAILS.NUMBER_OF_PARCELS')}
              </Typography>
              <Typography variant="bodyBold">{packages}</Typography>
            </div>
          )}
          {invoiceId && <LightGrayDivider orientation="vertical" />}
          {invoiceId && (
            <div className={styles.dataItems}>
              <Typography variant="body">{t('ORDERS.SHIPPING_DETAILS.INVOICE_NUMBER')}</Typography>
              <Typography variant="bodyBold">{invoiceId}</Typography>
            </div>
          )}
        </div>
      </div>
      {(isDownloadAvailable || packageTrackingInfos) && (
        <div className={styles.buttonRow}>
          <div className={styles.buttonWrapper}>
            {mobile ? (
              <>
                {isDownloadAvailable && (
                  <TertiaryIconButton
                    icon={<DownloadIcon />}
                    onClick={() => setDownloadMenuOpen(true)}
                  />
                )}
                <PopoverFromBottom
                  isOpen={downloadMenuOpen}
                  paperStyles={styles.downloadMenu}
                  onClose={() => setDownloadMenuOpen(false)}
                >
                  {isDeliverySlipPrintable && (
                    <div className={styles.downloadMenuItem}>{deliverySlipButton}</div>
                  )}
                  {isInvoiceAvailable && (
                    <div className={styles.downloadMenuItem}>{invoiceButton}</div>
                  )}
                </PopoverFromBottom>
              </>
            ) : (
              <>
                {isDeliverySlipPrintable && deliverySlipButton}
                {isInvoiceAvailable && invoiceButton}
              </>
            )}
          </div>
          {packageTrackingInfos && isSinglePackageTrackingLink && (
            <SecondaryButton
              id={'packageTrackingInfos'}
              leftIcon={<PackageIcon />}
              onClick={() => window.open(packageTrackingInfos[0].link, '_blank')}
            >
              {t('ORDERS.SHIPPING_DETAILS.TRACK_PACKAGE')}
            </SecondaryButton>
          )}
          {packageTrackingInfos && !isSinglePackageTrackingLink && (
            <div>
              {mobile ? (
                <div>
                  <SecondaryButton
                    leftIcon={<PackageIcon />}
                    onClick={(event) => popoverState.handleClick(event)}
                    rightIcon={<ArrowDownIcon />}
                  >
                    {t('ORDERS.SHIPPING_DETAILS.TRACK_PACKAGE')}
                  </SecondaryButton>
                  <PopoverFromBottom
                    isOpen={popoverState.open}
                    paperStyles={styles.packageTrackingMenu}
                    onClose={() => popoverState.onClose()}
                  >
                    {packageTrackingInfos?.map(
                      (packageTrackingInfo: PackageTrackingInfo, index: number) => (
                        <TertiaryButton
                          fullWidth={true}
                          onClick={() => window.open(packageTrackingInfo.link as string, '_blank')}
                        >
                          {t('ORDERS.SHIPPING_DETAILS.PACKAGE')} {index + 1}
                        </TertiaryButton>
                      )
                    )}
                  </PopoverFromBottom>
                </div>
              ) : (
                <Dropdown2
                  renderValue={() => (
                    <Typography>{t('ORDERS.SHIPPING_DETAILS.TRACK_PACKAGE')}</Typography>
                  )}
                  options={packageTrackingInfos?.map(
                    (packageTrackingInfo: PackageTrackingInfo, index: number) => ({
                      id: index.toString(),
                      value: packageTrackingInfo.link,
                      label: `${t('ORDERS.SHIPPING_DETAILS.PACKAGE')} ${index + 1}`,
                    })
                  )}
                  onChange={(value) => window.open(value as string, '_blank')}
                />
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  )
}
