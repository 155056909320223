import React, { FC, useEffect, useRef } from 'react'
import { useParams } from '@obeta/data/lib/hooks/useHistoryApi'

const OXOMI_STORY_CONTAINER_ID = 'story-container'

export const OxomiStoryPage: FC = () => {
  const params = useParams()
  const storyContainer = useRef(null)

  const oxomiInitialized = window?.oxomi?.isInitialized()
  useEffect(() => {
    if (window.oxomi && params.id && oxomiInitialized) {
      window.oxomi.embedStory({
        target: `#${OXOMI_STORY_CONTAINER_ID}`,
        story: params.id,
      })
    }
  }, [oxomiInitialized, params.id])

  useEffect(() => {
    function oxomiConfiguredCheck() {
      window.oxomi.embedStory({
        target: `#${OXOMI_STORY_CONTAINER_ID}`,
        story: params.id,
      })
    }
    document.addEventListener('oxomi-configured', oxomiConfiguredCheck)
    return () => {
      document.removeEventListener('oxomi-configured', oxomiConfiguredCheck)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id={OXOMI_STORY_CONTAINER_ID} ref={storyContainer} />
}
