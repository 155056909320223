import { createContext, useContext, useMemo, useState } from 'react'

// Models
import { OfferForListPage, OfferItemsForListPage } from '@obeta/models/lib/schema-models/offer-list'
import { OfferDisplayViewType, OffersInputWithAction } from '@obeta/models/lib/models'
import { OffersInput } from '@obeta/schema'

// Constants

export const SEARCH_OFFERS_LIMIT = 24
const INITIAL_OFFER_SEARCH_STATE: OffersInput = {
  filter: [],
  limit: SEARCH_OFFERS_LIMIT.toString(),
  offset: '0',
  // Note, following COD-16556, user-facing we order by 'startDate', but translate it backend-facing as 'offerId DESC'
  orderBy: 'offerId',
  orderDir: 'DESC',
  searchTerm: '',
}

const initialEmptyFunction = () => {
  //
}

export enum OfferStatus {
  NO_OFFERS = 'NO_OFFERS', // No offers, no filters applied
  NO_OFFERS_FILTERED = 'NO_OFFERS_FILTERED', // No offers due to filters
  HAS_OFFERS = 'HAS_OFFERS', // Offers exist and are displayed
}

type OfferDisplayViewTypeType = OfferDisplayViewType | 'all'

type ContextType = {
  offersStatus: OfferStatus
  offersDisplayView: OfferDisplayViewTypeType
  offers: OfferForListPage[]
  offersItems: OfferItemsForListPage[]
  searchOffersInput: OffersInputWithAction
  totalFavoriteOfferCount: number
  totalOfferCount: number
  updateOffersStatus: (status: OfferStatus) => void
  setOffers: (offers: OfferForListPage[]) => void
  setOffersItems: (offersItems: OfferItemsForListPage[]) => void
  setSearchOffersInput: (searchOffersInput: OffersInputWithAction) => void
  setTotalFavoriteOfferCount: (totalFavoriteOfferCount: number) => void
  setTotalOfferCount: (totalOfferCount: number) => void
  updateOffersDisplayView: (type: OfferDisplayViewTypeType) => void
  isOffersFetching: boolean
  setOffersFetching: (status: boolean) => void
}

export const OfferListContext = createContext<ContextType>({
  offersStatus: OfferStatus.NO_OFFERS,
  offersDisplayView: 'all',
  offers: [],
  offersItems: [],
  searchOffersInput: INITIAL_OFFER_SEARCH_STATE,
  totalFavoriteOfferCount: 0,
  totalOfferCount: 0,
  isOffersFetching: true,
  updateOffersStatus: initialEmptyFunction,
  setOffers: initialEmptyFunction,
  setOffersItems: initialEmptyFunction,
  setSearchOffersInput: initialEmptyFunction,
  setTotalFavoriteOfferCount: initialEmptyFunction,
  setTotalOfferCount: initialEmptyFunction,
  updateOffersDisplayView: initialEmptyFunction,
  setOffersFetching: initialEmptyFunction,
})

export const OfferListContextProvider = ({ children }) => {
  const [offersStatus, updateOffersStatus] = useState<OfferStatus>(OfferStatus.HAS_OFFERS)
  const [isOffersFetching, setOffersFetching] = useState(true)
  const [offers, setOffers] = useState<OfferForListPage[]>([])
  const [offersItems, setOffersItems] = useState<OfferItemsForListPage[]>([]) // Separate array to prevent infinite list page rerender by offers array (react-virtualized cache)
  const [searchOffersInput, setSearchOffersInput] = useState<OffersInputWithAction>(
    INITIAL_OFFER_SEARCH_STATE
  )
  const [offersDisplayView, updateOffersDisplayView] = useState<OfferDisplayViewTypeType>('all')
  const [totalFavoriteOfferCount, setTotalFavoriteOfferCount] = useState<number>(0)
  const [totalOfferCount, setTotalOfferCount] = useState<number>(0)

  const offerListContextProviderValue = useMemo(
    () => ({
      offersStatus,
      offers,
      offersItems,
      searchOffersInput,
      totalFavoriteOfferCount,
      totalOfferCount,
      offersDisplayView,
      isOffersFetching,
      updateOffersStatus,
      setOffers,
      setOffersItems,
      setSearchOffersInput,
      setTotalFavoriteOfferCount,
      setTotalOfferCount,
      updateOffersDisplayView,
      setOffersFetching,
    }),
    [
      offersDisplayView,
      offersStatus,
      offers,
      offersItems,
      searchOffersInput,
      totalFavoriteOfferCount,
      totalOfferCount,
      isOffersFetching,
    ]
  )

  return (
    <OfferListContext.Provider value={offerListContextProviderValue}>
      {children}
    </OfferListContext.Provider>
  )
}

export const useOfferListContext = () => {
  return useContext(OfferListContext)
}
