import { gql, useApolloClient } from '@apollo/client'
import { useCallback } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'
import { GetOrdersExportQuery, GetOrdersExportQueryVariables, OrdersInput } from '@obeta/schema'

export const getOrdersExportQuery = gql`
  query getOrdersExport($input: OrdersInput!) {
    getOrdersExport(input: $input) {
      success
      errorCode
      errorMessage
    }
  }
`

function showPendingMessage(message: string) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: NotificationType.OrdersExportPending,
    id: 'orders-export-pending',
    options: {
      message,
    },
  })
}

export const useGetOrdersExport = () => {
  const client = useApolloClient()
  const { t } = useTranslation()

  const getOrdersExport = useCallback(
    async (input: OrdersInput) => {
      showPendingMessage(t('ORDERS.NOTIFICATION.PENDING_ORDERS_EXPORT'))

      await client.query<GetOrdersExportQuery, GetOrdersExportQueryVariables>({
        query: getOrdersExportQuery,
        variables: {
          input,
        },
      })
    },
    [client, t]
  )

  return { getOrdersExport }
}
