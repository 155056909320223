import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import clsx from 'clsx'
import { Tooltip, Autocomplete, TextField, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import scssVariables from 'assets/theme/coreVariablesV2.module.scss'
import { UserType } from '@obeta/models/lib/models/Users/UserV2'
import { useUserOverviewTableData } from '@obeta/data/lib/hooks/user-overview/useUserOverviewTableData'
import { ReactComponent as MailIcon } from 'assets/icon/designsystem/mail_fixed_color.svg'
import { ReactComponent as DeleteIcon } from '@obeta/assets/icon/designsystem/delete_outline.svg'
import { ReactComponent as InfoIcon } from 'assets/icon/designsystem/info.svg'
//import { ReactComponent as AddIcon } from 'assets/icon/designsystem/add.svg'
import { TertiaryButton } from '@obeta/components/lib/custom-button/CustomButton'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { CollapseComponent } from '../../searchPage/filters/CollapseComponent'
import { CollapsibleContentContainer } from '../form/CollapsibleContentContainer'
import styles from './OrderPermissionCartNotification.module.scss'
import { useUserManagementReadOnly } from '../form/useUserManagementReadOnly'
import formStyles from '../form/form.module.scss'

type UserOption = {
  value: string
  label: string
  email: string
}

const getUserOption = (user): UserOption => {
  if (user.email === undefined) {
    // TODO fix this nicer (user appears to be a string containing the email only)
    return {
      value: user,
      label: user,
      email: user,
    }
  } else
    return {
      value: user.customerNumber,
      label: `${user.settings.name.substring(user.settings.name.indexOf(' '))}${
        user.type === UserType.sub ? '' : ' Admin'
      }`,
      email: user.emailData.email,
    }
}

const UserEmail: FC<{ option: UserOption }> = (props) => {
  const { email } = props.option
  return <Typography>{email}</Typography>
}

export const OrderPermissionCartNotification: FC<{
  usersToNotify: Array<string>
  setUsersToNotify: Dispatch<SetStateAction<Array<string>>>
}> = ({ usersToNotify, setUsersToNotify }) => {
  const [autocompleteInputValue, setAutocompleteInputValue] = useState('')
  const { t } = useTranslation()
  const { sortedItems } = useUserOverviewTableData()
  const { desktop } = useBreakpoints()
  const isReadOnlyUser = useUserManagementReadOnly()

  return (
    <CollapseComponent
      boxClassName={styles.titleWithoutCheckbox}
      disabledCheckbox={isReadOnlyUser}
      header={
        <div className={styles.titleWithTooltip}>
          {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.CART_NOTIFICATION')}
          {desktop && (
            <Tooltip
              title={t(
                'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.CART_NOTIFICATION'
              )}
            >
              <InfoIcon className={formStyles.tooltipIcon} color={scssVariables.$darkGray} />
            </Tooltip>
          )}
        </div>
      }
      ClassificationIcon={MailIcon}
    >
      <CollapsibleContentContainer>
        <div className={styles.allNotifyFields}>
          {usersToNotify.map((userEmail) => (
            <div key={userEmail} className={styles.userToNotifyContainer}>
              <Box className={styles.userToNotifyBox}>
                <UserEmail option={getUserOption(userEmail)} />
              </Box>
              <div className={styles.deleteIconContainer}>
                <TertiaryButton
                  leftIcon={<DeleteIcon />}
                  disabled={isReadOnlyUser}
                  onClick={() => {
                    setUsersToNotify((prevState) => prevState.filter((u) => u !== userEmail))
                  }}
                ></TertiaryButton>
              </div>
            </div>
          ))}
          {/* update condition when more than one user is allowed*/}
          {usersToNotify.length === 0 && (
            <Autocomplete
              freeSolo
              disabled={isReadOnlyUser}
              onBlur={(event) => {
                setUsersToNotify([(event.target as HTMLInputElement).value])
                setAutocompleteInputValue('')
              }}
              clearIcon={null}
              inputValue={autocompleteInputValue}
              onInputChange={(event, value, reason) => {
                if (reason === 'input') {
                  setAutocompleteInputValue(value)
                }
              }}
              className={clsx(styles.autoComplete, styles.notificationInput)}
              options={[...new Map(sortedItems.map((item) => [item.email, item])).values()]
                .filter((item) => !usersToNotify.find((userEmail) => userEmail === item.email))
                .filter((item) => item.emailData.isConfirmed)
                .map(getUserOption)}
              renderOption={(props, option: { email: string; label: string; value: string }) => (
                <li {...props}>
                  <UserEmail option={option} />
                </li>
              )}
              getOptionLabel={(option: { value: string; label: string }) => option.label}
              onChange={(event, value: UserOption) => {
                setAutocompleteInputValue('')
                const chosenUser = sortedItems.find((item) => item.customerNumber === value.value)
                if (chosenUser) {
                  setUsersToNotify([chosenUser.email])
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={t(
                    'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.USER_AUTOSELECT'
                  )}
                  onKeyDown={(event: React.KeyboardEvent) => {
                    if (event.key === 'Enter') {
                      event.preventDefault()
                      setUsersToNotify([(event.target as HTMLInputElement).value])
                      setAutocompleteInputValue('')
                    }
                  }}
                />
              )}
              isOptionEqualToValue={(option: UserOption, value: UserOption) =>
                option.email.toLowerCase() === value.email.toLowerCase() ||
                option.label.toLowerCase() === value.value.toLowerCase()
              }
              filterOptions={(options, { inputValue }) => {
                return options.filter(
                  (option: UserOption) =>
                    option.email.toLowerCase().includes(inputValue) ||
                    option.label.toLowerCase().includes(inputValue)
                )
              }}
              autoHighlight={false}
              sx={{
                '& .MuiAutocomplete-option[aria-selected="true"]': {
                  backgroundColor: 'transparent',
                },
                '& .MuiAutocomplete-option:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          )}
          {/* TODO
           <div style={{ display: 'flex' }}>
            <TertiaryButton
              leftIcon={<AddIcon />}
              disabled={autocompleteInputAmount >= 5}
              onClick={() => setAutocompleteInputAmount((prev) => prev + 1)}
              size={desktop ? 'small' : 'large'}
            >
              {t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.MORE')}
            </TertiaryButton>
          </div>
          */}
        </div>
      </CollapsibleContentContainer>
    </CollapseComponent>
  )
}
