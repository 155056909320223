import { FC } from 'react'
import { Controller, FieldValues, Message, RegisterOptions, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextField } from '@mui/material'
import Typography from '@mui/material/Typography'
import { FormHelperText } from '@obeta/components/lib/form-helper-text/FormHelperText'
import { IFirmData } from '@obeta/models/lib/models/Settings/Settings'

interface IFormField {
  name: keyof IFirmData
  label: string
  placeholder?: string
  errorText?: string
  rules?: Omit<
    RegisterOptions<FieldValues, keyof IFirmData>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >
}

export const FormField: FC<IFormField> = ({
  name,
  label,
  placeholder = label,
  rules,
  errorText,
}) => {
  const { t } = useTranslation()
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const defaultErrorMessage = t('SETTINGS.FIRM_DATA_SECTION.ERRORS.DEFAULT')
  const errorMessage = errorText || (errors?.[name]?.message as Message) || defaultErrorMessage

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={rules}
      render={({ field: { value, onChange, onBlur } }) => (
        <>
          <Typography paragraph mb={0.25}>
            {label}
          </Typography>
          <TextField
            fullWidth
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={!!errors[name]}
          />
          {errors[name] && <FormHelperText errorText={errorMessage} />}
        </>
      )}
    />
  )
}
