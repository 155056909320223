import React, { Dispatch, FC, SetStateAction } from 'react'
import { CollapseComponent } from '../../searchPage/filters/CollapseComponent'
import { RadioGroup } from '@mui/material'
import {
  CART_PERMISSION_STATUS_ALL_CARTS,
  CART_PERMISSION_STATUS_OWN_CART,
  CART_PERMISSION_STATUS_PROJECT_CARTS,
  CartPermissionStatus,
} from '@obeta/utils/lib/user-management/cart-status'
import { Badge, BadgeContainer } from '@obeta/components/lib/badge/Badge'
import { useTranslation } from 'react-i18next'
import { RadioButtonDropdownLine, RadioButtonLine } from '../form/FormComponents'
import { CollapsibleContentContainer } from '../form/CollapsibleContentContainer'
import styles from './OrderPermissionCartGroup.module.scss'
import { useUserManagementReadOnly } from '../form/useUserManagementReadOnly'
import { usePossibleProjects } from '@obeta/data/lib/hooks/user-overview/usePossibleProjects'

type Props = {
  status: CartPermissionStatus
  setStatus: Dispatch<SetStateAction<CartPermissionStatus>>
  projectCartIds: string[]
  setProjectCartIds: Dispatch<SetStateAction<string[]>>
}

export const OrderPermissionCartGroup: FC<Props> = ({
  status,
  setStatus,
  projectCartIds,
  setProjectCartIds,
}) => {
  const { t } = useTranslation()
  const possibleProjects = usePossibleProjects()

  // TODO get initial status from backend response when ready
  const isReadOnlyUser = useUserManagementReadOnly()
  return (
    <CollapseComponent
      header={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.CART')}
      disabledCheckbox={isReadOnlyUser}
    >
      {possibleProjects && (
        <CollapsibleContentContainer noIndentation>
          <RadioGroup
            className={styles.radioContainer}
            value={status}
            onChange={(event, value) => {
              if (value !== CART_PERMISSION_STATUS_PROJECT_CARTS) {
                setStatus(value as CartPermissionStatus)
                setProjectCartIds([])
              } else {
                setStatus(CART_PERMISSION_STATUS_PROJECT_CARTS)
              }
            }}
          >
            <RadioButtonLine
              value={CART_PERMISSION_STATUS_OWN_CART}
              label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.OWN_CART')}
              tooltip={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.OWN_CART')}
              disabled={isReadOnlyUser}
            />
            <RadioButtonLine
              value={CART_PERMISSION_STATUS_ALL_CARTS}
              label={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.ALL_CARTS')}
              tooltip={t(
                'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.ALL_CARTS'
              )}
              disabled={isReadOnlyUser}
            />
            <RadioButtonDropdownLine
              value={CART_PERMISSION_STATUS_PROJECT_CARTS}
              disabled={isReadOnlyUser}
              title={t('USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.PROJECT_CART')}
              options={possibleProjects.map(({ id, name }) => ({ id, title: name }))}
              onSelect={(option) => {
                setProjectCartIds([option.id])
                setStatus(CART_PERMISSION_STATUS_PROJECT_CARTS)
              }}
              tooltipText={t(
                'USER_MANAGEMENT.USER_MANAGEMENT_PAGE.ORDER_PERMISSION.TOOLTIPS.PROJECT_CART'
              )}
              openSelectViaRadiobutton
            />
            <BadgeContainer className={styles.cartBadgeContainer}>
              {projectCartIds.map((cartId) => {
                const foundCart = possibleProjects?.find((cart) => cart.id === cartId)
                if (foundCart)
                  return (
                    <Badge disabled={isReadOnlyUser} onRemove={() => setProjectCartIds([])}>
                      {foundCart.name}
                    </Badge>
                  )
                return null
              })}
            </BadgeContainer>
          </RadioGroup>
        </CollapsibleContentContainer>
      )}
    </CollapseComponent>
  )
}
