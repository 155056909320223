import { CollectionsOfDatabase, RxDatabase } from 'rxdb'
import { datadogRum } from '@datadog/browser-rum'
interface INewsMeta {
  lastTimeOrderMixed: string
  order: number[]
}

const now = () => {
  return new Date().toISOString()
}

const setNewOrderWithTimestamp = (db: RxDatabase<CollectionsOfDatabase>, order: number[]) => {
  return db.upsertLocal<INewsMeta>('newsmeta', { lastTimeOrderMixed: now(), order })
}

//canMixNewsOrder ensures that local oducment "newsmeta" exists
export const validateIndicesTimestamp = async (db: RxDatabase<CollectionsOfDatabase>) => {
  const localDoc = await db.getLocal<INewsMeta>('newsmeta')
  if (!localDoc) {
    return true
  }

  const lastTimeOrderMixed = localDoc.get('lastTimeOrderMixed')
  if (!lastTimeOrderMixed) {
    return true
  }

  try {
    const last = new Date(lastTimeOrderMixed as string)
    return last.getDate() !== new Date().getDate()
  } catch (err) {
    console.error(err)
  }

  return true
}

export const generateRandomIndices = (length: number) => {
  const array = generateIndices(length)
  let top = length
  let tmp
  let current
  if (top) {
    while (--top) {
      current = Math.floor(Math.random() * (top + 1))
      tmp = array[current]
      array[current] = array[top]
      array[top] = tmp
    }
  }

  return array
}

const generateIndices = (length: number) => {
  return [...Array(length)].map((_, i) => i)
}

const saveIndicesData = async (
  db: RxDatabase<CollectionsOfDatabase>,
  currentOrder: number[],
  length: number
) => {
  try {
    await setNewOrderWithTimestamp(db, currentOrder)
    return currentOrder
  } catch (err) {
    // fallback
    datadogRum.addError(err)
    return generateIndices(length)
  }
}

export const getRandomIndices = async (db: RxDatabase<CollectionsOfDatabase>, length: number) => {
  const localDoc = await db.getLocal<INewsMeta>('newsmeta')
  let currentOrder = localDoc?.get('order') as number[]
  let shouldGenerateNewIndices = currentOrder?.length !== length
  if (!shouldGenerateNewIndices) {
    shouldGenerateNewIndices = await validateIndicesTimestamp(db)
  }

  if (!shouldGenerateNewIndices) {
    return saveIndicesData(db, currentOrder, length)
  }

  currentOrder = generateRandomIndices(length)
  return saveIndicesData(db, currentOrder, length)
}
