import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CellMeasurerCache } from 'react-virtualized'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import {
  InfiniteWindowScroller as OfferListContent,
  TInfiniteLoaderProps,
} from '@obeta/components/lib/window-scroller'
import { OfferActionsBar } from '@obeta/components/lib/offers/OfferActionsBar'
import { OfferListItemV2 } from '@obeta/components/lib/offers/OfferListItemV2'
import { useAuthenticatedRoute } from '@obeta/data/lib/hooks/useAuthenticatedRoute'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useCartsv2WithPricesAndStock } from '@obeta/data/lib/hooks/useCartsv2WithPricesAndStock'
import { useDebouncedEffect } from '@obeta/data/lib/hooks/useDebouncedEffect'
import { useEntities } from '@obeta/data/lib/hooks'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useMeasuredRowRenderer } from '@obeta/data/lib/hooks/useMeasuredRowRenderer'
import { useOfferSearch } from '@obeta/data/lib/hooks/useOfferSearch'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'
import {
  OfferListContextProvider,
  OfferStatus,
  SEARCH_OFFERS_LIMIT,
  useOfferListContext,
} from '@obeta/data/lib/stores/useOfferListContext'
import styles from './offerListPage.module.scss'
import { OfferItemsForListPage } from '@obeta/models/lib/schema-models/offer-list'
import {
  OfferDisplayViewType,
  OfferListPageAction,
  OffersInputWithAction,
  OfferV2,
  ShoppingCartForDropdown,
} from '@obeta/models/lib/models'
import { EmptyCard } from '@obeta/components/lib/empty-card/EmptyCard'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { trackClick } from '@obeta/utils/lib/tracking'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { addUniqueValue, removeValues } from '@obeta/utils/lib/array'
import { OfferFilter, OfferV3 } from '@obeta/schema'
import { usePullToRefresh } from '@obeta/components/lib/pull-to-refresh'
import {
  AcceptedOfferCancellationReasonStrings,
  ConfirmWithOfferCancellationReasonSelect,
} from '@obeta/components/lib/alert-and-confirm/ConfirmWithOfferCancellationReasonSelect'
import offerListItemV2Styles from '@obeta/components/lib/offers/OfferListItemV2.module.scss'
import { useRequestOfferCancellation } from '@obeta/data/lib/hooks/useRequestOfferCancellation'

const ROW_HEIGHT = 367
const INITIAL_CACHE = {
  defaultHeight: ROW_HEIGHT,
  fixedWidth: true, // measure only the height, improved performance (vertical scrolling)
  minHeight: 339, // expected minimum row height (with long offer name)
}

type OfferWithPlaceholder = OfferV3 & { isPlaceholder?: boolean }

const OfferListPage: FC = () => {
  return (
    <OfferListContextProvider>
      <OfferListPageContent />
    </OfferListContextProvider>
  )
}

// New offers page
const OfferListPageContent: FC = () => {
  useAuthenticatedRoute()
  const { desktop, mobile, tabletAll } = useBreakpoints()
  const history = useHistory()
  const { isLoggedIn } = useUserDataV2()

  const { t } = useTranslation()

  const title = t('OFFERS.OFFERS')

  const { searchOffers } = useOfferSearch()
  const offersV2 = useEntities<OfferV2>('offersv2')

  const cartsV2 = useCartsv2WithPricesAndStock()
  const shoppingCartsToMoveCartItemsTo: ShoppingCartForDropdown[] = []
  cartsV2.forEach((cartV2) => {
    let offerName = ''
    if (cartV2.offerId !== '') {
      offerName = offersV2.find((offer) => offer.id === cartV2.offerId)?.offerName ?? ''
    }
    const shoppingCartForDropdown: ShoppingCartForDropdown = {
      id: cartV2.id,
      name: cartV2.name,
      articleCount: cartV2.items.length,
      offerId: cartV2.offerId,
      offerName: offerName,
      count: cartV2.items.length,
    }
    shoppingCartsToMoveCartItemsTo.push(shoppingCartForDropdown)
  })
  const {
    offersStatus,
    offers,
    offersItems,
    searchOffersInput,
    totalFavoriteOfferCount,
    totalOfferCount,
    setOffers,
    setSearchOffersInput,
    setTotalFavoriteOfferCount,
    offersDisplayView,
    updateOffersDisplayView,
    isOffersFetching,
  } = useOfferListContext()

  const { cancelOffer } = useRequestOfferCancellation()

  usePullToRefresh({
    onRefresh() {
      return searchOffers(searchOffersInput)
    },
  })

  // https://github.com/bvaughn/react-virtualized/blob/master/docs/CellMeasurer.md#cellmeasurercache
  const [cache, setCache] = useState(new CellMeasurerCache(INITIAL_CACHE))
  const [rvData, setRvData] = useState<{
    elementsPerRow: number
    limit: number
    rowCount: number
    rowTotalCount: number
  }>({
    elementsPerRow: 0,
    limit: 0,
    rowCount: 0,
    rowTotalCount: 0,
  })
  const [offerPurchasable, setOfferPurchasable] = useState<string[]>([])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [offerToShowCancellationDialogFor, setOfferToShowCancellationDialogFor] =
    useState<string>('')

  // Subscribe to delete and no permission events
  useEffect(() => {
    const sub = getEventSubscription().subscribe((event) => {
      if (
        event.type === EventType.Data &&
        event.notificationType === NotificationType.OfferDelete &&
        'offerId' in event.options
      ) {
        setOfferPurchasable(offerPurchasable.concat(event.options.offerId))
      }
      if (
        event.type === EventType.Data &&
        event.notificationType === NotificationType.OfferNoPermission
      ) {
        history.replace(ShopRoutes.Root)
      }
    })
    return () => {
      sub.unsubscribe()
    }
  }, [history, offerPurchasable])

  // Reset cache on filter / search term change
  useEffect(() => {
    if (searchOffersInput.offset === '0') {
      setCache(new CellMeasurerCache(INITIAL_CACHE))
    }
  }, [offers, searchOffersInput])

  // Init search on searchTerm change
  useDebouncedEffect(
    () => {
      setSearchOffersInput({
        action: OfferListPageAction.Search,
        filter: searchOffersInput.filter,
        limit: searchOffersInput.limit,
        offset: '0',
        orderBy: searchOffersInput.orderBy,
        orderDir: searchOffersInput.orderDir,
        searchTerm,
      })
    },
    [searchTerm],
    500
  )

  // Search offers on input variables change
  useEffect(() => {
    searchOffers(searchOffersInput)
    // eslint-disable-next-line
  }, [searchOffersInput])

  // Set elements per row, row count and total row count by breakpoint and offer list length
  useEffect(() => {
    const totalOfferCurrentForCurrentView =
      offersDisplayView === 'all' ? totalOfferCount : totalFavoriteOfferCount

    if (offers && offers.length > 0) {
      mobile &&
        setRvData({
          elementsPerRow: 1,
          limit: SEARCH_OFFERS_LIMIT,
          rowCount: offers.length,
          rowTotalCount: totalOfferCurrentForCurrentView ?? 0,
        })
      tabletAll &&
        setRvData({
          elementsPerRow: 2,
          limit: SEARCH_OFFERS_LIMIT,
          rowCount: Math.ceil(offers.length / 2),
          rowTotalCount: totalOfferCurrentForCurrentView
            ? Math.ceil(totalOfferCurrentForCurrentView / 2)
            : 0,
        })
      desktop &&
        setRvData({
          elementsPerRow: 3,
          limit: SEARCH_OFFERS_LIMIT,
          rowCount: Math.ceil(offers.length / 3),
          rowTotalCount: totalOfferCurrentForCurrentView
            ? Math.ceil(totalOfferCurrentForCurrentView / 3)
            : 0,
        })
    }
  }, [
    desktop,
    mobile,
    offers,
    tabletAll,
    totalOfferCount,
    totalFavoriteOfferCount,
    offersDisplayView,
  ])

  /**
   * Get offer items by offer id.
   * @param id Offer id
   */
  const getOfferItemsByOfferId = useCallback(
    (id: string): OfferItemsForListPage | undefined => {
      return offersItems.find((offerItems) => offerItems.offerId === id)
    },
    [offersItems]
  )

  const isRowLoaded = useCallback<TInfiniteLoaderProps['isRowLoaded']>(
    ({ index }) => {
      return Boolean(offers[index * rvData.elementsPerRow]) // Check if actual offer is loaded by elements per row value (breakpoint)
    },
    [offers, rvData]
  )

  /**
   * Handler to set display filter.
   * @param display OfferDisplay
   */
  const onDisplaySelect = useCallback(
    (display: OfferDisplayViewType) => {
      updateOffersDisplayView(display)
      const displayValue = display === 'all' ? null : display

      setSearchOffersInput({
        action: OfferListPageAction.DisplaySelect,
        filter: setOfferListDisplayFilter(displayValue) ?? searchOffersInput.filter,
        limit: searchOffersInput.limit,
        offset: '0',
        orderBy: searchOffersInput.orderBy,
        orderDir: searchOffersInput.orderDir,
        searchTerm: searchOffersInput.searchTerm,
      })
    },
    // eslint-disable-next-line
    [searchOffersInput]
  )

  /**
   * Load more rows (offers) when InfiniteLoader threshold is reached.
   * @param params Row start and stop index
   */
  const onLoadMoreRows = useCallback(
    async () => {
      setSearchOffersInput({
        filter: searchOffersInput.filter,
        limit: rvData.limit.toString(),
        offset: offers.length.toString(),
        orderBy: searchOffersInput.orderBy,
        orderDir: searchOffersInput.orderDir,
        searchTerm: searchOffersInput.searchTerm,
      })
    },
    // eslint-disable-next-line
    [offers, rvData, searchOffersInput]
  )

  /**
   * Handler on toggle favorite. Change total favorite offer count.
   */
  const onToggleFavorite = useCallback(
    (id: string, isFavorite: boolean) => {
      trackClick('toggle-favorite', {
        id,
        isFavorite,
      })

      if (isFavorite) {
        setTotalFavoriteOfferCount(totalFavoriteOfferCount + 1)
      }
      if (!isFavorite) {
        const updatedTotalFavoriteOfferCount = totalFavoriteOfferCount - 1
        if (offersDisplayView === 'isFavorite') {
          if (updatedTotalFavoriteOfferCount < 1) {
            // Remove isFavorite filter from search offers input if last favorite got removed to display show all tab
            const updatedSearchOffersInput: OffersInputWithAction = {
              ...searchOffersInput,
              action: OfferListPageAction.ToggleFavorite,
              filter: searchOffersInput.filter.filter((filter) => filter !== 'isFavorite'),
              offset: '0',
            }
            setSearchOffersInput(updatedSearchOffersInput)
            updateOffersDisplayView('all')
          } else {
            // Remove unliked offer from list
            setOffers(offers.filter((offer) => offer.offerId !== id))
          }
        }
        setTotalFavoriteOfferCount(updatedTotalFavoriteOfferCount)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [offersDisplayView, offers, searchOffersInput, totalFavoriteOfferCount]
  )

  const setOfferListDisplayFilter = useCallback(
    (display: OfferDisplayViewType | null): OfferFilter[] => {
      if (!display) {
        return removeValues(['isFavorite'], searchOffersInput.filter)
      } else {
        return addUniqueValue(display as OfferFilter, searchOffersInput.filter)
      }
    },
    [searchOffersInput]
  )

  const { rowRenderer } = useMeasuredRowRenderer({
    cache,
    render: ({ index }) => {
      // Extract offers by current row index + calculated elements per row by current breakpoint
      const offersSlice = offers.slice(
        index * rvData.elementsPerRow,
        index * rvData.elementsPerRow + rvData.elementsPerRow
      )

      const fullRow = Array.from({ length: rvData.elementsPerRow }).map(
        (_, i) => offersSlice[i] || { isPlaceholder: true }
      ) as OfferWithPlaceholder[]

      if (offersSlice && offersSlice.length > 0) {
        return (
          <div className={styles.row} key={index} data-test-id={`idx-${index}`}>
            {fullRow.map((offer, i) =>
              offer.isPlaceholder ? (
                <div
                  key={`placeholder-${i}`}
                  className={offerListItemV2Styles.item}
                  aria-hidden="true"
                />
              ) : (
                <OfferListItemV2
                  notPurchasable={offerPurchasable.includes(offer.offerId)}
                  key={offer.offerId}
                  offer={offer}
                  offerItems={getOfferItemsByOfferId(offer.offerId)}
                  carts={shoppingCartsToMoveCartItemsTo}
                  onToggleFavorite={onToggleFavorite}
                  setOfferToShowCancellationDialogFor={setOfferToShowCancellationDialogFor}
                />
              )
            )}
          </div>
        )
      }
      return null
    },
  })

  const onCancelOffer = (reason: AcceptedOfferCancellationReasonStrings) => {
    cancelOffer({ offerId: offerToShowCancellationDialogFor, reason: reason })
  }
  return (
    <div className={styles.offerList}>
      {/* Edge case handling - either no offers or no permission to see offers */}
      {offersStatus === OfferStatus.NO_OFFERS && isLoggedIn && !isOffersFetching && (
        <div className={styles.noOffers}>
          <div className={styles.noOffersBoxWrapper}>
            <EmptyCard title={t('OFFERS.NO_CURRENT_OFFERS')} />
          </div>
        </div>
      )}
      {offersStatus === OfferStatus.HAS_OFFERS && (
        <Grid container direction={'column'}>
          <div className={styles.appBarWrapper}>
            <Typography className={styles.title} variant={'h3'} color={'text.primary'}>
              {title}
            </Typography>
            <OfferActionsBar
              activeDisplay={offersDisplayView}
              searchTerm={searchTerm}
              onDisplaySelect={onDisplaySelect}
              onSearch={setSearchTerm}
            />
            <OfferListContent
              infiniteLoader={{
                isRowLoaded,
                loadMoreRows: onLoadMoreRows,
                minimumBatchSize: rvData.limit / rvData.elementsPerRow, // Amount of offers to load at a time. Dependent on breakpoint / elements per row
                threshold: 1, // Load more items when within the last row
              }}
              list={{
                rowRenderer,
                rowHeight: cache.rowHeight,
                deferredMeasurementCache: cache,
              }}
              rowCount={rvData.rowCount} // Row count for loaded offers
              rowTotalCount={rvData.rowTotalCount} // Total row count for offers (including not loaded yet offers)
            />
          </div>
        </Grid>
      )}
      <ConfirmWithOfferCancellationReasonSelect
        handleConfirm={onCancelOffer}
        handleClose={() => setOfferToShowCancellationDialogFor('')}
        handleAbort={() => setOfferToShowCancellationDialogFor('')}
        openDialog={Boolean(offerToShowCancellationDialogFor !== '')}
      ></ConfirmWithOfferCancellationReasonSelect>
    </div>
  )
}

export default OfferListPage
