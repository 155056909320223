import clsx from 'clsx'
import { useEffect } from 'react'
import { FormSection } from '../../../form-section/FormSection'
import { useTranslation } from 'react-i18next'
import { AllPricesSection } from './sections/all-prices-section/AllPricesSection'
import { OfferPriceSection } from './sections/offer-price-section/OfferPriceSection'
import { PriceTypeSection } from './sections/price-type-section/PriceTypeSection'
import { useGetDataSheetPriceInfo } from '@obeta/data/lib/hooks'
import { useFormContext } from 'react-hook-form'
import { convertInitialPriceSettings } from '@obeta/utils/lib/settingsConverters'
import { DatsheetPrintOptions } from '@obeta/models/lib/models'
import styles from './PrintOptionIndividualForm.module.scss'

export const PrintOptionIndividualForm = ({
  isUsedOnSettingsPage,
}: {
  isUsedOnSettingsPage?: boolean
}) => {
  const { t } = useTranslation()
  const { reset } = useFormContext()
  const dataSheetPriceInfo = useGetDataSheetPriceInfo()

  useEffect(() => {
    if (dataSheetPriceInfo) {
      reset({
        type: DatsheetPrintOptions.INDIVIDUAL,
        ...convertInitialPriceSettings(dataSheetPriceInfo),
      })
    }
  }, [dataSheetPriceInfo, reset])

  return (
    <form data-testid="individual-config-form">
      <div
        className={clsx(styles.wrapper, {
          [styles.isUsedOnSettingsPageWrapper]: isUsedOnSettingsPage,
        })}
      >
        <FormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.ALL_PRICES')}>
          <AllPricesSection />
        </FormSection>
        <FormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.OFFER_PRICE')}>
          <OfferPriceSection isUsedOnSettingsPage={isUsedOnSettingsPage} />
        </FormSection>
        <FormSection title={t('INDIVIDUAL_DATASHEET.FORM_SECTION.PRICE_TYPE')}>
          <PriceTypeSection />
        </FormSection>
      </div>
    </form>
  )
}
