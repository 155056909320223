import { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './MenuButton.module.scss'

type MenuButtonProps = {
  title: string
  active: boolean
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  disabled?: boolean
  rightAdornment?: JSX.Element | null
}

export const MenuButton: FC<MenuButtonProps> = (props) => {
  const { title, active, disabled, rightAdornment, onClick } = props

  return (
    <div
      className={clsx(
        styles.menuButton,
        !disabled && styles.menuButtonEnabled,
        active && styles.menuButtonActive
      )}
      onClick={(e) => !disabled && onClick(e)}
    >
      <Typography
        className={clsx(styles.menuButtonTitle, active && styles.menuButtonTitleActive)}
        variant="bodyBold"
      >
        {title}
      </Typography>
      {rightAdornment}
    </div>
  )
}
