import { FC, useState, useCallback } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/icon/designsystem/close.svg'
import styles from './RequestInvoicesDialog.module.scss'
import { useTranslation } from 'react-i18next'
import {
  DarkButton,
  SecondaryButton,
  TertiaryButton,
} from '@obeta/components/lib/custom-button/CustomButton'
import { splitBySpecialCharacters } from '@obeta/utils/lib/split-by-special-characters'
import { useDispatchInvoices } from '@obeta/data/lib/hooks/useDispatchInvoice'

type Props = {
  open: boolean
  onClose: () => void
}

export const RequestInvoiceBadge: FC<{ removeItem: () => void }> = ({ children, removeItem }) => {
  return (
    <div className={styles.badge}>
      <div>
        <Typography>{children}</Typography>
      </div>
      <CloseIcon onClick={removeItem} className={styles.badgeCloseIcon} />
    </div>
  )
}

export const RequestInvoicesDialog: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const [invoicesToBeRequested, setInvoicesToBeRequested] = useState<string[]>([])
  const { dispatchInvoiceZip, dispatchInvoicePdf } = useDispatchInvoices()

  const hasNoInvoicesToBeRequested = invoicesToBeRequested.length === 0

  const handleInputChange = useCallback(
    (event) => {
      const inputValue = event.target.value
      const filteredValue = inputValue.replace(/[^0-9,; ]/g, '')
      setInputValue(filteredValue)
    },
    [setInputValue]
  )

  const hasInputs = inputValue.length > 0

  const applyInputs = useCallback(() => {
    const partsFromInput = splitBySpecialCharacters(inputValue, [' ', ',', ';'])
    const nextItems = [...invoicesToBeRequested, ...partsFromInput]
    setInvoicesToBeRequested(nextItems)
    setInputValue('')
  }, [inputValue, invoicesToBeRequested])

  return (
    <Dialog className={styles.dialog} open={open}>
      <DialogTitle className={styles.title}>
        <Typography variant="h4" color="text.primary">
          {t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.TITLE')}
        </Typography>
        <TertiaryButton iconColor={'secondary'} leftIcon={<CloseIcon />} onClick={onClose} />
      </DialogTitle>
      <DialogContent className={styles.content}>
        <Typography variant="bodyBold">
          {t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.DESCRIPTION')}
        </Typography>
        <div>
          <div className={styles.inputTitleContainer}>
            <Typography>{t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.INPUT_TITLE')}</Typography>
          </div>
          <TextField
            placeholder={t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.INPUT_PLACEHOLDER')}
            inputProps={{
              'aria-label': t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.TITLE'),
              inputMode: 'search',
            }}
            className={styles.textField}
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            fullWidth
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                applyInputs()
              }
            }}
          />
          {!hasNoInvoicesToBeRequested && (
            <div className={styles.badgesContainer}>
              <div className={styles.badges}>
                {invoicesToBeRequested.map((item, index) => (
                  <RequestInvoiceBadge
                    removeItem={() => {
                      const nextItems = invoicesToBeRequested.filter(
                        (_, filterIndex) => index !== filterIndex
                      )
                      setInvoicesToBeRequested(nextItems)
                    }}
                  >
                    {item}
                  </RequestInvoiceBadge>
                ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <SecondaryButton onClick={onClose}>
          {t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.ABORT')}
        </SecondaryButton>
        {!hasInputs && !hasNoInvoicesToBeRequested ? (
          <DarkButton
            disabled={hasNoInvoicesToBeRequested}
            onClick={async () => {
              if (invoicesToBeRequested.length === 1) {
                const [invoiceId] = invoicesToBeRequested
                await dispatchInvoicePdf(invoiceId)
                onClose()
              } else {
                await dispatchInvoiceZip(invoicesToBeRequested)
                onClose()
              }
            }}
          >
            {t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.SUBMIT')}
          </DarkButton>
        ) : (
          <DarkButton onClick={applyInputs} disabled={!hasInputs}>
            {t('MY_ACCOUNTING.REQUEST_INVOICES_DIALOG.CONFIRM')}
          </DarkButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
