import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Typography } from '@mui/material'

// Hooks
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'

// Styles
import styles from './EmptyCard.module.scss'

// UI
import { DarkButton, SecondaryButton } from '../custom-button/CustomButton'

// Utils
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

type Props = {
  showContact?: boolean
  subtitle?: string
  title: string
  customAction?: {
    title: string
    onClick: () => void
  }
}

export const EmptyCard: FC<Props> = (props) => {
  const { showContact = false, subtitle, title, customAction } = props

  const { tabletAll, desktop } = useBreakpoints()
  const history = useHistory()
  const { redirectToStartPage } = useRedirect()
  const { t } = useTranslation()

  const onSecondButtonClick = () => {
    if (!customAction) {
      history.push(`/stores`)
      return
    }

    customAction.onClick()
  }

  const secondButtonTitle = !customAction ? t('COMMON.STORES') : customAction.title

  return (
    <Card className={styles.card}>
      <div className={styles.text}>
        <Typography align="center" variant="headline4Bold">
          {title}
        </Typography>
        {subtitle && (
          <Typography align="center" variant="subTitle">
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={styles.buttons}>
        <DarkButton onClick={redirectToStartPage} fullWidth={true}>
          {t('COMMON.START_PAGE')}
        </DarkButton>
        {(desktop || tabletAll) && (
          <SecondaryButton fullWidth={true} onClick={onSecondButtonClick}>
            {secondButtonTitle}
          </SecondaryButton>
        )}
        {showContact &&
          !isUiTarget('app') /*app has no view for contact currently*/ &&
          (desktop || tabletAll) && (
            <SecondaryButton
              fullWidth={true}
              onClick={() => {
                history.push(`/contact`)
              }}
            >
              {t('COMMON.CONTACT')}
            </SecondaryButton>
          )}
      </div>
    </Card>
  )
}
