import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { ShoppingCartShippingForm } from '../shoppingcart/ShoppingCartShippingForm'
import { ShoppingCartDeliveryOptions } from '../shoppingcart/ShoppingCartDeliveryOptions'
import { useTranslation } from 'react-i18next'
import React, { useEffect } from 'react'

import { ReactComponent as SpecialTourIcon } from '@obeta/assets/icon/designsystem/Sondertour.svg'
import styles from './ShoppingCartCheckoutInformation.module.scss'

import { useUserV2 } from '@obeta/data/lib/hooks/useUserV2'

import { AddressV2 } from '@obeta/models/lib/models/ShoppingCart/AddressV2'
import { DeliveryAddressV2 } from '@obeta/models/lib/models/ShoppingCart/DeliveryAddressV2'
import {
  ShippingOptions,
  ShippingType,
} from '@obeta/models/lib/models/ShoppingCart/ShippingOptions'
import {
  ShoppingCartFilterEnum,
  ShoppingCartV2,
} from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { UserAddressType, UserAddressV2 } from '@obeta/models/lib/models/Users/UserV2'

import { AddressManagementModal } from './AddressManagementModal'
import { Confirm } from '../alert-and-confirm/Confirm'
import { useForm } from 'react-hook-form'

interface Props {
  createUserAddress: (address: AddressV2, customName: string) => void
  deleteUserAddress: (addressId: string) => void
  updateUserAddress: (addressId: string, address: AddressV2, customName: string) => void
  onSelectUserAddressId: (id: string) => void
  selectedDeliveryAddress: DeliveryAddressV2
  selectedDeliveryAddressId: string
  cartContainsSpecialItems: boolean
  showAddressManagmentModal: boolean
  setShowAddressManagmentModal: (i: boolean) => void
  cartContainsNonSendableItems: boolean
  preFilterCartItems: (mode: ShoppingCartFilterEnum) => void
  shippingOptionsForDelivery: ShippingOptions[]
  updateShippingAddress: (deliveryAddress: DeliveryAddressV2, addressId?: string) => void
  userAddresses: UserAddressV2[]
  cart: ShoppingCartV2
  setValuesForMemoryBox: (param) => void
  selectedShippingDate: string
  selectedShippingType: string
  updateShippingDate: (date: string) => void
  updateShippingType: (shippingType: ShippingType) => void
  userCanReadAddresses: boolean
  userCanWriteAddresses: boolean
  userCanWriteCartAddress: boolean
  toggleEditingStateOfDeliveryAddress: (i: boolean) => void
  isDeactivated?: boolean
  outOfStockMessage?: string
  cartId: string
}

export const ShoppingCartCheckoutDeliveryInformation: React.FC<Props> = (props) => {
  const {
    createUserAddress,
    deleteUserAddress,
    toggleEditingStateOfDeliveryAddress,
    updateUserAddress,
    onSelectUserAddressId,
    selectedDeliveryAddress,
    showAddressManagmentModal,
    preFilterCartItems,
    selectedDeliveryAddressId,
    setShowAddressManagmentModal,
    shippingOptionsForDelivery,
    updateShippingAddress,
    userAddresses,
    cart,
    setValuesForMemoryBox,
    selectedShippingDate,
    selectedShippingType,
    updateShippingDate,
    updateShippingType,
    userCanReadAddresses,
    userCanWriteAddresses,
    userCanWriteCartAddress,
    isDeactivated,
    outOfStockMessage,
    cartId,
  } = props

  const { t } = useTranslation()
  const { cartContainsSpecialItems } = props
  const { cartContainsNonSendableItems } = props
  const userV2 = useUserV2()

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
    trigger,
  } = useForm<DeliveryAddressV2 & { address: string; zip: string }>({
    mode: 'onChange',
    defaultValues: {
      name1: selectedDeliveryAddress?.name1,
      name2: selectedDeliveryAddress?.name2,
      address: selectedDeliveryAddress?.street + ' ' + selectedDeliveryAddress?.houseNumber,
      city: selectedDeliveryAddress?.city,
      zip: selectedDeliveryAddress?.zipCode,
    },
  })

  useEffect(() => {
    reset({
      name1: selectedDeliveryAddress?.name1,
      name2: selectedDeliveryAddress?.name2,
      address: selectedDeliveryAddress?.street + ' ' + selectedDeliveryAddress?.houseNumber,
      city: selectedDeliveryAddress?.city,
      zip: selectedDeliveryAddress?.zipCode,
    })
  }, [reset, selectedDeliveryAddress])

  let isSpecialTour = false
  let isMainAddress = false
  let isDeliveryAddress = false

  const userAddress = userAddresses.find(
    (userAddress) => selectedDeliveryAddressId === userAddress?.addressId
  )
  if (userAddress !== undefined) {
    if (userAddress?.addressType === UserAddressType.SpecialTour) {
      isSpecialTour = true
    }
    if (userAddress?.addressType === UserAddressType.MainAddress) {
      isMainAddress = true
    }
    if (userAddress?.addressType === UserAddressType.DeliveryAddress) {
      isDeliveryAddress = true
    }
  }

  const [showUnsavedChangesConfirm, setShowUnsavedChangesConfirm] = React.useState(false)
  const [isEditableCopyOfSpecialTour, setIsEditableCopyOfSpecialTour] = React.useState(false)

  useEffect(() => {
    toggleEditingStateOfDeliveryAddress(isDirty || isEditableCopyOfSpecialTour)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, isEditableCopyOfSpecialTour])

  const handleEditSpecialTourAsNormalTour = () => {
    setIsEditableCopyOfSpecialTour(true)
  }

  const handleConfirmCloseDespiteUnsavedChanges = () => {
    // close confirm
    setShowUnsavedChangesConfirm(false)
    // close ShoppingCartShippingForm with Sondertour data
    if (isEditableCopyOfSpecialTour) {
      setIsEditableCopyOfSpecialTour(false)
      setShowAddressManagmentModal(true)
    } else {
      reset({
        name1: selectedDeliveryAddress?.name1,
        name2: selectedDeliveryAddress?.name2,
        address: selectedDeliveryAddress?.street + ' ' + selectedDeliveryAddress?.houseNumber,
        city: selectedDeliveryAddress?.city,
        zip: selectedDeliveryAddress?.zipCode,
      })
      setShowAddressManagmentModal(true)
    }
  }

  const handleCancelCloseBecauseOfUnsavedChanges = () => {
    // close confirm
    setShowUnsavedChangesConfirm(false)
  }

  const handleAddressManagementButtonClick = () => {
    if (isEditableCopyOfSpecialTour || isDirty) {
      setShowUnsavedChangesConfirm(true)
    } else {
      setShowAddressManagmentModal(true)
    }
  }

  return (
    <div>
      <Grid className={styles.gridContainer} container direction={'column'}>
        <Grid>
          <Box className={styles.headerBox}>
            <Typography data-testid="checkout-delivery-header-text" variant={'h4'}>
              {t('SHOPPING_CART.CHECKOUT.SHIPPING_DETAILS')}
            </Typography>
            {userCanWriteCartAddress && userCanReadAddresses && (
              <Button
                data-testid="address-management-button"
                className={styles.addressManagementButton}
                variant={'tertiary'}
                size={'small'}
                onClick={handleAddressManagementButtonClick}
              >
                {t('SHOPPING_CART.NAVIGATION.ADDRESS_MANAGEMENT')}
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>

      {isSpecialTour && !isEditableCopyOfSpecialTour && (
        <Box className={styles.specialTourBox}>
          <SpecialTourIcon />
          <Button
            className={styles.specialTourAsNormalTourButton}
            variant={'tertiary'}
            size={'small'}
            onClick={handleEditSpecialTourAsNormalTour}
          >
            {t('SHOPPING_CART.CHECKOUT.TREAT_AS_NORMAL_TOUR')}
          </Button>
        </Box>
      )}
      {isMainAddress && (
        <Box className={styles.specialTourBox}>
          <Typography variant={'bodyBold'}>{t('ADDRESSES.MAIN_ADDRESS')}</Typography>
        </Box>
      )}
      {isDeliveryAddress && (
        <Box className={styles.specialTourBox}>
          <Typography variant={'bodyBold'}>{t('ADDRESSES.DELIVERY_ADDRESS')}</Typography>
        </Box>
      )}

      {userV2?.permissions.Cart_canWriteAddress &&
      (!isSpecialTour || (isEditableCopyOfSpecialTour && selectedDeliveryAddress)) ? (
        <ShoppingCartShippingForm
          control={control}
          handleSubmit={handleSubmit}
          errors={errors}
          isDirty={isDirty}
          isValid={isValid}
          reset={reset}
          trigger={trigger}
          deliveryAddress={selectedDeliveryAddress}
          updateShippingAddress={updateShippingAddress}
          isEditableCopyOfSpecialTour={isEditableCopyOfSpecialTour}
          setIsEditableCopyOfSpecialTour={setIsEditableCopyOfSpecialTour}
        />
      ) : (
        <>
          <Stack>
            <Typography>{selectedDeliveryAddress?.name1}</Typography>
            <Typography>{selectedDeliveryAddress?.name2}</Typography>
            <Typography>
              {selectedDeliveryAddress?.street} {selectedDeliveryAddress?.houseNumber}
            </Typography>
            <Typography>
              {selectedDeliveryAddress?.zipCode} {selectedDeliveryAddress?.city}
            </Typography>
          </Stack>
          <Grid size={{ xs: 12 }}>
            <Divider className={styles.deliveryDivider} />
          </Grid>
        </>
      )}

      <Grid className={styles.optionsGrid} container direction={'column'}>
        <ShoppingCartDeliveryOptions
          cartContainsSpecialItems={cartContainsSpecialItems}
          cartContainsNonSendableItems={cartContainsNonSendableItems}
          shippingOptionsForDelivery={shippingOptionsForDelivery}
          preFilterCartItems={preFilterCartItems}
          specialTour={isSpecialTour}
          cart={cart}
          setValuesForMemoryBox={setValuesForMemoryBox}
          selectedShippingType={selectedShippingType}
          selectedShippingDate={selectedShippingDate}
          updateShippingDate={updateShippingDate}
          updateShippingType={updateShippingType}
          isDeactivated={isDeactivated}
          outOfStockMessage={outOfStockMessage}
          cartId={cartId}
        />
      </Grid>
      {showAddressManagmentModal && (
        <AddressManagementModal
          isOpen={showAddressManagmentModal}
          createUserAddress={createUserAddress}
          deleteUserAddress={deleteUserAddress}
          updateUserAddress={updateUserAddress}
          selectedDeliveryAddressId={selectedDeliveryAddressId}
          onSelectUserAddressId={onSelectUserAddressId}
          setCheckoutAddressSearchBox={setShowAddressManagmentModal}
          userAddresses={userAddresses}
          userCanWriteAddresses={userCanWriteAddresses}
        />
      )}
      <Confirm
        heading={t('ADDRESSES.UNSAVED_CHANGES')}
        body={t('ADDRESSES.CONFIRM.CONTINUE_DESPITE_UNSAVED_CHANGES')}
        handleConfirm={handleConfirmCloseDespiteUnsavedChanges}
        handleCancel={handleCancelCloseBecauseOfUnsavedChanges}
        openConfirmDialog={showUnsavedChangesConfirm}
      />
    </div>
  )
}
