import { Box } from '@mui/material'
import { useEffect } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ReactComponent as InfoIcon } from '@obeta/assets/icon/designsystem/info.svg'
import { Tooltip } from '@obeta/components/lib/tooltip/Tooltip'
import {
  useBreakpoints,
  useGetDataSheetCustomerCompanyData,
  useSaveDataSheetCustomerCompanyData,
} from '@obeta/data/lib/hooks'
import { CompanySection } from './CompanySection'
import { ContactSection } from './ContactSection'
import { FormActionFooter } from './FormActionFooter'
import { OpeningHoursSection } from './OpeningHoursSection'
import { SettingsFormWrapper } from './SettingsFormWrapper'
import { UploadLogoSection } from './UploadLogoSection'
import { useLogoUpload } from './useLogoUpload'
import {
  convertInitialFirmDataSettings,
  convertFirmDataSettingsToSubmit,
} from '@obeta/utils/lib/settingsConverters'
import { IFirmData } from '@obeta/models/lib/models/Settings/Settings'
import styles from './SettingsPage.module.scss'

const FirmDataSettings = () => {
  const { t } = useTranslation()
  const dataSheetCustomerCompanyData = useGetDataSheetCustomerCompanyData()
  const updateDataSheetCustomerCompanyData = useSaveDataSheetCustomerCompanyData()
  const { onLogoSubmit } = useLogoUpload()
  const { desktop } = useBreakpoints()

  const { reset, handleSubmit, setValue, clearErrors } = useFormContext<IFirmData>()

  useEffect(() => {
    if (!dataSheetCustomerCompanyData) return

    const firmDataValues = convertInitialFirmDataSettings(dataSheetCustomerCompanyData)
    reset(firmDataValues)
  }, [reset, dataSheetCustomerCompanyData])

  const onFirmDataSettingsSubmit = async (data: IFirmData) => {
    const firmDataSettingsToSubmit = convertFirmDataSettingsToSubmit(data)
    const response = await updateDataSheetCustomerCompanyData(firmDataSettingsToSubmit)
    await onLogoSubmit()
    if (response) reset(data)
  }

  const onReset = () => {
    setValue('companyLogo', null)
    clearErrors('companyLogo')
    if (dataSheetCustomerCompanyData) {
      reset(convertInitialFirmDataSettings(dataSheetCustomerCompanyData))
    } else {
      reset()
    }
  }

  const uploadLogoSectionTitle = (
    <Box className={styles.uploadLogoSectionTitle}>
      {t('SETTINGS.FIRM_DATA_SECTION.UPLOAD_LOGO')}
      {desktop && (
        <Tooltip
          arrow
          placement="right"
          title={t('SETTINGS.FIRM_DATA_SECTION.UPLOAD_LOGO_MAX_SIZE')}
        >
          <InfoIcon />
        </Tooltip>
      )}
    </Box>
  )

  return (
    <div className={styles.content}>
      <SettingsFormWrapper title={t('SETTINGS.FIRM_DATA_SECTION.COMPANY')}>
        <CompanySection />
      </SettingsFormWrapper>
      <SettingsFormWrapper title={t('SETTINGS.FIRM_DATA_SECTION.CONTACT')}>
        <ContactSection />
      </SettingsFormWrapper>
      <SettingsFormWrapper title={t('SETTINGS.FIRM_DATA_SECTION.OPENING_HOURS')}>
        <OpeningHoursSection />
      </SettingsFormWrapper>
      <SettingsFormWrapper title={uploadLogoSectionTitle}>
        <UploadLogoSection />
      </SettingsFormWrapper>
      <FormActionFooter onSubmit={handleSubmit(onFirmDataSettingsSubmit)} onReset={onReset} />
    </div>
  )
}

export const FirmDataSettingsForm = () => {
  const methods = useForm<IFirmData>({ mode: 'onBlur' })

  return (
    <FormProvider {...methods}>
      <FirmDataSettings />
    </FormProvider>
  )
}
